import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { Alert } from "../alert";
import { useSelector, useDispatch } from "react-redux";

export const ProfileDetails = (props) => {
  const [alert, setAlert] = useState({ open: false, data: "" });
  const alertClose = () => setAlert({ ...alert, open: false });
  

  const [values, setValues] = useState({
    username: localStorage.getItem("email"),
    firstname: localStorage.getItem("firstName"),
    lastname: localStorage.getItem("lastName")
  });

  return (
    <>
      <Alert open={alert.open} data={alert.data} close={alertClose} />
      <form autoComplete="off" noValidate {...props}>
        <Card>
          <Grid item md={6} xs={12}>
            <CardHeader className="profile-details-title" title="Profile" />
          </Grid>
          <Divider />
          <CardContent>
            <Grid container spacing={3} className="details-tab">
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Username"
                  name="usertName"
                  disabled
                  value={values.username}
                  variant="outlined"
                  className="detaila-field"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="text"
                  disabled
                  value={values.firstname}
                  variant="outlined"
                  className="detaila-field"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastname"
                  disabled
                  value={values.lastname}
                  variant="outlined"
                  className="detaila-field"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </form>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Box, Container, ListItem, List, Button, Typography  } from "@mui/material";
import { DashboardLayout } from "../components/dashboard-layout";
import axios from "axios";
import * as Constants from '../constants';
import CircularProgress from "@mui/material/CircularProgress"
import { PDFExport } from '@progress/kendo-react-pdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const QuestionAnswers = (props) => {
  const pdfExportComponent = React.useRef(null);
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(false)
  const candidateId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const getQuestionAnswers = () => {
    var data  = {
      "candidateId" : candidateId
    }
    setLoading(true)
    axios
      .post(
          Constants.APIURL + "candidateConversation", data
        )
      .then((response) => {
        setLoading(false)  
        if(response.data.conversations && response.data.conversations.length >0){
          const updatedTableRows = response.data.conversations.map((item, index) => ({
            S_NO: index + 1,
            ...item,
          }));
          setTableRows(updatedTableRows);     
         
        }
      });
  }

  useEffect(() => {
    if (tableRows.length < 1) {
      getQuestionAnswers();
    }
  }, [tableRows.length]);

  useEffect(() => {

    getQuestionAnswers();
  }, []);


  return (
    <>
      <DashboardLayout>  
      <Box
         component="main"
         sx={{
           flexGrow: 1,
           pt: 3,
           mt: 2,
           margin: "15px",
          //  display: "flex",
           alignItems: "center",
           mb: 2,
         }}
         
      >
           <Button
            color="success"
            variant="contained"
            sx={{ padding: '10px 20px', my: "8px", float: "right !important" }}
            className="buttonTheme"
            onClick={() => {
              if (pdfExportComponent.current) {
                pdfExportComponent.current.save();
              }
            }}
          >
          <FileDownloadIcon />
          Download
        </Button>  
      </Box>

      {<PDFExport paperSize="A3" margin="2cm" ref={pdfExportComponent} fileName={localStorage.getItem("viewCandidateName").replace(" ", "_")+ "_interview_details.pdf"}> 
      <Box
         sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          mb: 2,
          marginTop: "-10px"
        }}
        className="title_header"
      >
         <Typography sx={{ m: 1  , color: "#30687B" }} variant="h4">
          {localStorage.getItem("viewCandidateName")}&nbsp;Interview Details          
        </Typography>
   
        </Box>    
   
        <Container maxWidth={false} className="qa_list center_port">
         {loading ? <CircularProgress/> : tableRows.length > 0 ? tableRows.map( (item, index) => (   
              <List
                sx={{
                      listStyleType: 'disc',
                      listStylePosition: 'inside',
                    }}
                    key={index}
              >

                <div className="qa_body">
                <ListItem
                  sx={{
                  fontSize: "14px", lineHeight : "20px"
                  }}
                >
                <b className="listSubheading">Question {index + 1} </b> <span>:</span> {item.question !== null && item.question !== undefined && item.question}
                </ListItem>
                <ListItem   sx={{
                  fontSize: "14px", lineHeight : "20px"
                  }}>                    
                <b className="listSubheading">Answer </b> <span>:</span> {item.userAnswer}
                </ListItem>
                <ListItem   sx={{
                  fontSize: "14px", lineHeight : "20px"
                  }}>
                <b className="listSubheading">Validation </b> <span>:</span> {item.AIValidation}                    
                </ListItem>
                </div>
                <div className="qa_footer">

                <ListItem >
                <b className="listSubheading"> Score </b><span>:</span>{item.score}
                </ListItem>
                </div>

              </List>
            )):  <List
            sx={{
                  listStyleType: 'disc',
                  listStylePosition: 'inside',
                  display: 'inline',
                  flexDirection: 'row',
                  padding: 0
                }}
          >
            <ListItem
              sx={{
                fontWeight: "700",
                color: "#306a88",
                fontSize: "18px",
                padding: '10px !important'
              }}
            >
              Candidate has not started the interview yet.
            </ListItem>
          </List>
}
                
          </Container>
          </PDFExport> } 
      </DashboardLayout>
    </>
  );
};

export default QuestionAnswers;

import React, { useEffect, useState } from "react";
import { Box, Button, Container } from "@mui/material";
import CandidateToolbar from "../components/candidate/CandidateToolbar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { DashboardLayout } from "../components/dashboard-layout";
import moment from "moment";
import axios from "axios";
import * as Constants from '../constants';
import CircularProgress from "@mui/material/CircularProgress"

const Candidates = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [tableRows, setTableRows] = useState([]);

  const handleViewDetails = (e) => {
    e.preventDefault();
    localStorage.setItem("viewCandidateName", e.target.dataset.name);
    window.location.href = "questionAnswers/" + e.target.dataset.id;
  };
  
  const getName = (fName, lName) => {
    return fName + " " + lName;
  }
  const viewCandidateReport = (e) => {
    e.preventDefault();
    localStorage.setItem("viewCandidateName", e.target.dataset.name);
    window.location.href = "candidateReport/" + e.target.dataset.id;
  };


  const columns = [ 
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email Id",
      minWidth: 290,
      flex: 1,
    },
    {
      field: "testName",
      headerName: "Test Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "interviewStatus",
      headerName: "Interview Status",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "score",
      headerName: "Score",
      maxWidth: 100,
      flex: 1,
    },
    {
      field: "view",
      headerName: "View Details",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <p>
        <a href = "" data-id = {params.id} data-name = { getName(params.row.firstName, params.row.lastName) } onClick = {handleViewDetails}>View Details</a>
         { params.row.interviewStatus === "Completed" &&   
        <span>&nbsp; |&nbsp; <a href = "" data-id = {params.id} data-name = { getName(params.row.Firstname, params.row.lastname) } onClick = {viewCandidateReport}>View Report</a></span>
      }
        </p>
      ),
    },
    {
      field: "created_date",
      headerName: "Added On",
      minWidth: 100,
      flex: 1,
      accessor: "created_date",
      renderCell: (row) => (
        <span>{moment(row.value).format("MM-DD-YYYY")}</span>
      ),
    }
  ];

  const getCandidates = () => {
    setLoading(true);
    var JwtToken = localStorage.getItem("JwtToken");
    console.log("JwtToken", JwtToken);
    axios
      .get(
        Constants.APIURL + "getAllUsersCandidates", {
          // Constants.APIURL + "candidate", {
          headers: {
            'Authorization': `${JwtToken}`
          }})
      .then((response) => {
        setLoading(false);
        var i = 0;
        var candidates = response.data.map((item) => {
          i++;
          return {
            id: item.id,  
            display_id: i,
            view: "",
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            interviewStatus: item.interviewStatus ?  ( item.Results.length > 0 ? "Completed" : "Started" ) : "Incomplete",
            testName: (item.Test !== undefined && item.Test !== null) ? item.Test.programmingLanguage : "",
            score: item.Results != undefined && item.Results !== null && item.Results.length > 0 ? item.Results[0].result : 0,
            created_date: item.createdAt
          };
        });

        setTableRows(candidates);
        setLoading(false);

        if (candidates.length > 10) {
          setRowsPerPage([10, 25, 50, 100]);
        }
      });
  }

  const search = (value) => {
    setSearchValue(value);
    if (value !== "" && value !== "undefined") {
      let filterRow = [];
      filterRow = tableRows.filter((item) => {
        return (
          item.no_of_questions.includes(searchValue) ||
          item.name.toLowerCase().includes(searchValue)
        );
      });
      setTableRows(filterRow);
      if (filterRow.length > 10) {
        setRowsPerPage([10, 25, 50, 100]);
      }
    }
    else {
      setTableRows(tableRows);
    }
  }

  useEffect(() => {
    if (tableRows.length < 1) {
      getCandidates();
    }
  }, [tableRows.length]);

  useEffect(() => {
    getCandidates();
  }, []);
  
  return (
    <>
      <DashboardLayout>        
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pt: 3,
            mt: 2,
          }}
        >
          <Container maxWidth={false}>
          <div
              style={{
                height: 5,
                width: "100%",
                marginTop: "24px",
                backgroundColor: "white",
              }}
            >
            </div>
            <CandidateToolbar
              search={(value) => search(value.toLowerCase())}         
            />
            <div
              style={{
                height: 650,
                width: "100%",
                marginTop: "24px",
                backgroundColor: "white",
              }}
              className="center_port"
            >
              {tableRows && tableRows.length > 0 &&
                <DataGrid
                  rows={tableRows}
                  columns={columns}                  
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  pageSize={pageSize}
                  rowsPerPageOptions={rowsPerPage}
                  disableColumnSelector
                  sortingOrder={['asc', 'desc']}
                  hideFooter={tableRows.length < 1 ? true: false}
                  className="candidates-grid "    
                />
              }
              {loading && tableRows.length === 0 &&
                <CircularProgress />
              }
               {!loading && tableRows.length === 0 &&
                <DataGrid
                rows={tableRows}
                columns={columns}  
                hideFooter={tableRows.length < 1 ? true: false}                
              />
              }
            </div>
            
          </Container>
        </Box>
      </DashboardLayout>
    </>
  );
};

export default Candidates;

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox, Container, Box, List, ListItem , Button, TextField, FormGroup, Grid} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import * as Constants from '../../constants';

import { Alert } from "../alert";
import EasyEdit, { Types } from 'react-easy-edit';
import { DirectionsSubwayFilled } from "@mui/icons-material";
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from "@mui/material/CircularProgress"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const TestDetails = (props) => {
  const [alert, setAlert] = useState({ open: false, data: "" });

  const [AIQuestions, setAIQuestions] = useState([]);
  const [CodingQuestions, setCodingQuestions] = useState([]);
  const [OwnQuestions, setOwnQuestions] = useState([]);

  let [aiQuestionCurrentValue, setAiQuestionCurrentValue] = useState([])
  let [ownQuestionCurrentValue, setOwnQuestionCurrentValue] = useState([])
  let [codingQuestionCurrentValue, setCodingQuestionCurrentValue] = useState([])  
  const [loading, setLoading] = useState(false);
  const [testId, setTestId] = useState("");
  const [error, setError] = useState([]);
  const [draftFlag, setDraftFlag] = useState(false);
  const [reload, setReload] = useState(false);

  const [extraQuestionsData, setExtraQuestionsData] = useState([]);  
  
  const [selectedAIQuestions, setSelectedAIQuestions] = useState([]);
  const [selectedCodingQuestions, setSelectedCodingQuestions] = useState([]);
  const [selectedOwnQuestions, setSelectedOwnQuestions] = useState([]);
  const [showCheckBoxes, setShowCheckBoxes] = useState(false);
  
  const [currentExtraQuestions, setCurrentExtraQuestionsData] = useState("");

  const setCurrentExtraQuestions = (value) => {
    setError("");
    setCurrentExtraQuestionsData(value);
    setDraftFlag(true)
  }

  const handleAddExtraQuestion = () => {
    if(currentExtraQuestions !== ""){
      if(extraQuestionsData.length > 0){
        let temp = extraQuestionsData;
        temp.push(currentExtraQuestions);
        setExtraQuestionsData(temp);
      }
      else {
        let temp = [];
        temp.push(currentExtraQuestions);
        setExtraQuestionsData(temp);
      }
      setCurrentExtraQuestionsData("");
    }
    else{
      setError("Please enter your question to add.");
    }
    setDraftFlag(true)
  }
  
  const getExtraquestionData = (question) => {
    var temp = [];
    if(question !== "")
      temp.push(question)
    return temp
  }

  const handleDeleteQuestions = () => {
   
    if(selectedAIQuestions && selectedAIQuestions.length > 0){
      var AI = AIQuestions   
      selectedAIQuestions.sort(function(a, b) { return b - a; }).map((item) => {
        AI.splice(item, 1);
      })
    }
    
    if(selectedCodingQuestions && selectedCodingQuestions.length > 0){
      var coding = CodingQuestions
      selectedCodingQuestions.sort(function(a, b) { return b - a; }).map((item) => {
        coding.splice(item, 1);
      })
    }

    if(selectedOwnQuestions && selectedOwnQuestions.length > 0){
      var own = OwnQuestions
      selectedOwnQuestions.sort(function(a, b) { return b - a; }).map((item) => {
        own.splice(item, 1);
      })
    }

    if((selectedAIQuestions && selectedAIQuestions.length > 0) || 
        (selectedCodingQuestions && selectedCodingQuestions.length > 0) || 
        (selectedOwnQuestions && selectedOwnQuestions.length > 0)){              
          setReload(!reload)
          setDraftFlag(true) 
    }
    else{
      setShowCheckBoxes(true)
    }
  }

  const handleSelectAIQuestion = (e, codeIndex) => {
    var temp = selectedAIQuestions
    if(temp.includes(codeIndex) ){
      var index = temp.indexOf(codeIndex);
      temp.splice(index, 1);  
      setSelectedAIQuestions(temp) 
    }
    else{
      temp.push(codeIndex)
      setSelectedAIQuestions(temp) 
    }       
    setDraftFlag(true)    
  }

  const handleSelectCodingQuestion = (e, codeIndex) => {
    var temp = selectedCodingQuestions
    if(temp.includes(codeIndex)){
      var index = temp.indexOf(codeIndex);
      temp.splice(index, 1);  
      setSelectedCodingQuestions(temp) 
    }
    else{
      temp.push(codeIndex)
      setSelectedCodingQuestions(temp)
    }
    setDraftFlag(true)    
  }

  const handleSelectOwnQuestion = (e, codeIndex) => {
    var temp = selectedOwnQuestions
    if(temp.includes(codeIndex)){
      var index = temp.indexOf(codeIndex);
      temp.splice(index, 1);  
      setSelectedOwnQuestions(temp) 
    }
    else{
      temp.push(codeIndex)
      setSelectedOwnQuestions(temp)
    }  
    setDraftFlag(true)    
  }
  
 
  const handleCloseExtraQuestion = (codeIndex) => {
    extraQuestionsData.splice(codeIndex, 1);
    setExtraQuestionsData([...extraQuestionsData ]);
    setDraftFlag(true)
  }

  const aiQuestionChange = (e, questionIndex) => {
   var temp = aiQuestionCurrentValue
    temp[questionIndex] = e    
    setAiQuestionCurrentValue(temp)
   
  }

  const codingQuestionChange = (e, questionIndex) => {
    var temp = codingQuestionCurrentValue;
    temp[questionIndex] = e
    setCodingQuestionCurrentValue(temp)    
  }

  const ownQuestionChange = (e, questionIndex) => {
    var temp = ownQuestionCurrentValue;
    temp[questionIndex] = e
    setOwnQuestionCurrentValue(temp)
  }
  
  const handleUpdateAIQuestion = () => {
    var temp = aiQuestionCurrentValue.filter(function (el) {
      return el != "";
    });
    setAIQuestions(temp)    
    setDraftFlag(true)
    setReload(!reload)
  };
  
  const handleUpdateCodingQuestion = () => {
    var temp = codingQuestionCurrentValue.filter(function (el) {
      return el != "";
    });
    setCodingQuestions(temp)
    setDraftFlag(true)
    setReload(!reload)
  };

  const handleUpdateOwnQuestion = () => {
    var temp = ownQuestionCurrentValue.filter(function (el) {
      return el != "";
    });
    setOwnQuestions(temp)
    setDraftFlag(true)
    setReload(!reload)
  };

  useEffect(()=> {
    setAIQuestions(props.testdetails.aiQuestions)   
    setCodingQuestions( props.testdetails.codingQuestions)
    setOwnQuestions(props.testdetails.ownQuestions)
    setTestId(props.testdetails.id) 
    setAiQuestionCurrentValue(props.testdetails.aiQuestions)
    setCodingQuestionCurrentValue( props.testdetails.codingQuestions )    
    setOwnQuestionCurrentValue(props.testdetails.ownQuestions)  
    setCurrentExtraQuestionsData("");   
    setExtraQuestionsData([]);
    setSelectedOwnQuestions([])
    setSelectedCodingQuestions([])
    setSelectedAIQuestions([])
    setShowCheckBoxes(false)
    setError("")
}, [props.open])

useEffect(()=> {
  setAiQuestionCurrentValue(AIQuestions)
  setCodingQuestionCurrentValue(CodingQuestions)
  setOwnQuestionCurrentValue(OwnQuestions)
  setSelectedOwnQuestions([])
  setSelectedCodingQuestions([])
  setSelectedAIQuestions([])
  setShowCheckBoxes(false) 
}, [reload])

  const getTotalNoOfQuestions = () => {
    var adminQuestionsCount =  extraQuestionsData && extraQuestionsData.length > 0 ? extraQuestionsData.length : ((currentExtraQuestions !== "") ? 1 : 0);
    var AIQuestionsCount =  AIQuestions && AIQuestions.length > 0 ? AIQuestions.length : 0;
    var CodingQuestionsCount =  CodingQuestions && CodingQuestions.length > 0 ? CodingQuestions.length : 0;
    var OwnQuestionsCount =  OwnQuestions && OwnQuestions.length > 0 ? OwnQuestions.length : 0;
    
    return adminQuestionsCount + AIQuestionsCount + CodingQuestionsCount + OwnQuestionsCount;
  }

  const saveChanges = () => {
    setError("");
    console.log("OwnQuestions", draftFlag)
    if(!draftFlag){
      props.close()
      return false;
    }
    else{
      setLoading(true)
      var data = {
        "aiQuestions": AIQuestions,
        "ownQuestions" : extraQuestionsData && extraQuestionsData.length > 0 ? OwnQuestions.concat(extraQuestionsData) : OwnQuestions.concat(getExtraquestionData(currentExtraQuestions)),
        "codingQuestions" : CodingQuestions,
        "id" : testId,
        numberOfSelectedQuestions : getTotalNoOfQuestions()
      }

      console.log(data)

      var JwtToken = localStorage.getItem("JwtToken");  
      axios
        .post( 
          Constants.APIURL + "updateQuesrtionsByTest" , data ,  {
            headers: {
              'Authorization': `${JwtToken}`,
              'Content-Type': 'application/json'
            }})
      .then((response) => {
        console.log(response);
        if(response.status === 422){
          setError(response.data.message);
          setAlert({ open: true, data: response.data.message });
          props.close()
          window.location.reload();      
        }
        else if (response.data.status !== 200) {
            setAlert({ open: true, data: "The Questions are updated successfully." });
            props.close()
            window.location.reload();      
        }
        setLoading(false)
      }).catch((err)=>
      setAlert({ open: true, data: err}));
     
    }
  }

  const cancel = () => {

    console.log("Cancelled.");
  };
  
  return (
    <>
      <Alert
        open={alert.open}
        data={alert.data}
        close={() => setAlert({ ...alert, open: false })}
      />
      <BootstrapDialog
        onClose={props.close}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth="xl"
        className="xxl_modal"
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width : "100%"
          }}
          className = "view-candidates"
        >
        <Container maxWidth={false} className="px-0">
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.close}
            sx={{
              fontSize: "1.5rem",
              fontWeight: "700",
              color: (theme) => theme.palette.primary.main,
              borderBottom: "3px solid #D1D5DB",
            }}
          >
            <div className="row">
              <div className="interview_questions_heading" >
                Interview Questions
              </div>
              <div className="interview_questions_buttons"  >
                <Box>
                  <Grid container spacing={1} align="center" direction="row">
                    <Grid item xs={6} >  
                      {/* <IconButton
                        aria-label="close"
                        sx={{
                          top: -10,
                          color: "#cd5959"
                        }}
                        onClick={handleDeleteQuestions}
                      >
                        <DeleteForeverIcon fontSize = "large" />
                      </IconButton>       */}
                    <Button
                      color="error"
                      variant="contained"
                      sx={{ px: 20}}
                      className="get_question delete_btn"            
                      type="button"
                      style={{padding: '10px 30px'}}
                      onClick={handleDeleteQuestions}
                    >
                    Delete        
                    </Button>             
                    </Grid>
                    {/* <Grid item xs={6}>                       
                      <IconButton
                        aria-label="close"
                        sx={{
                          top: -10,
                          color: "#306a88 !important"
                        }}
                        onClick={handleEditQuestions}
                      >
                        <EditIcon fontSize = "large" />
                      </IconButton>
                    </Grid> */}
                  </Grid>
              </Box>
               
              </div>
            </div>
           
           
          </BootstrapDialogTitle>
        
          <Typography style={{ color: "red" }}>{error}</Typography>
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                marginBottom: '30px',
                padding: '10px 30px'
              }}
              className="all_questions_list"
            >

            <Container sx={{ width: "100%", mb: 0, mt: 3}} style={{padding : '0'}}>
              <FormGroup className="list_item">
                {AIQuestions && AIQuestions.length > 0 && AIQuestions.map((item , index) => (
                  <List
                      sx={{
                            listStylePosition: 'outside',
                            display: 'flex',
                            flexDirection: 'row',
                            padding: 0
                          }}
                        className="question_list">
                        
                        { showCheckBoxes &&  
                        <ListItem disablePadding={true} sx = {{ width : '30px'}} key = {"aidel"+index}>                             
                          <Checkbox                           
                            value = {(aiQuestionCurrentValue && aiQuestionCurrentValue[index] !== "" && aiQuestionCurrentValue[index] !== undefined)
                            ? aiQuestionCurrentValue[index] : item }
                            onChange={(e) => {handleSelectAIQuestion(e, index)}}
                          />
                        </ListItem>
                        }
                        
                        <ListItem disablePadding={true} sx = {{ width : '100%'}} key = {"ai"+index} className="questions"> 
                        <span className="s-no">{index + 1}.</span>                            
                        <EasyEdit
                          type={Types.TEXTAREA}
                          onSave={handleUpdateAIQuestion}
                          onCancel={cancel}
                          saveButtonLabel="Save"
                          cancelButtonLabel="Cancel"
                          value = {(aiQuestionCurrentValue && aiQuestionCurrentValue[index] !== "" && aiQuestionCurrentValue[index] !== undefined)
                          ? aiQuestionCurrentValue[index] : item }
                          onFocus = {(e) => {aiQuestionChange(e, index)}}
                          saveButtonStyle = "easy-edit-button"
                          saveOnBlur = {true}                       
                        /> 
                        </ListItem>
                    </List>
                ))}               
              </FormGroup>             
            </Container>

            <Container sx={{ width: "100%", my: 0 }} style={{padding : '0'}}>
              <FormGroup className="list_item">
              {CodingQuestions && CodingQuestions.length > 0 && CodingQuestions.map((item , index) => (
                  <List
                      sx={{
                            listStylePosition: 'outside',
                            display: 'flex',
                            flexDirection: 'row',
                            padding: 0
                          }}
                        className="question_list">
                        
                        { showCheckBoxes &&  
                          <ListItem disablePadding={true} sx = {{ width : '30px'}} key = {"aidel"+index}>                             
                            <Checkbox                           
                            value = {(codingQuestionCurrentValue && codingQuestionCurrentValue[index] !== "" && codingQuestionCurrentValue[index] !== undefined)
                            ? codingQuestionCurrentValue[index] : item }
                            onChange={(e) => {handleSelectCodingQuestion(e, index)}}
                            />
                        
                          </ListItem>
                        }
                      
                        <ListItem disablePadding={true} sx = {{ width : '100%'}} key = {"ai"+index} className="questions">   
                        <span className="s-no">
                          {AIQuestions && AIQuestions.length > 0 ? (AIQuestions.length + index + 1) : index + 1}.
                        </span>
                        <EasyEdit
                          type={Types.TEXTAREA}
                          onSave={handleUpdateCodingQuestion}
                          onCancel={cancel}
                          saveButtonLabel="Save"
                          cancelButtonLabel="Cancel"
                          value = {(codingQuestionCurrentValue && codingQuestionCurrentValue[index] !== "" && codingQuestionCurrentValue[index] !== undefined)
                          ? codingQuestionCurrentValue[index] : item }
                          onFocus = {(e) => {codingQuestionChange(e, index)}}
                          saveButtonStyle = "easy-edit-button"
                          saveOnBlur = {true}                       
                        /> 
                        </ListItem>
                    </List>
                ))}               
              </FormGroup>             
            </Container>

            <Container sx={{ width: "100%", my: 2 }} style={{padding : '0'}}>
              <FormGroup className="list_item">
                {OwnQuestions && OwnQuestions.length > 0 && OwnQuestions.map((item , index) => (
                  <List
                      sx={{
                            listStylePosition: 'inside',
                            display: 'flex',
                            flexDirection: 'row',
                            padding: 0
                          }}
                        className="question_list">
                          { showCheckBoxes &&  
                          <ListItem disablePadding={true} sx = {{ width : '30px'}} key = {"aidel"+index}>                             
                            <Checkbox                           
                              value = {(ownQuestionCurrentValue && ownQuestionCurrentValue[index] !== "" && ownQuestionCurrentValue[index] !== undefined)
                              ? ownQuestionCurrentValue[index] : item }
                              onChange={(e) => {handleSelectOwnQuestion(e, index)}}
                            />
                          </ListItem>
                          }
                       
                        <ListItem disablePadding={true} sx = {{ width : '100%'}} key = {"own"+index} className="questions">   
                          <span className="s-no">
                            {AIQuestions && AIQuestions.length > 0 ? 
                            (CodingQuestions && CodingQuestions.length > 0 ? (AIQuestions.length + CodingQuestions.length + index + 1) : AIQuestions.length +  index + 1) :  
                            (CodingQuestions && CodingQuestions.length > 0 ? CodingQuestions.length + index + 1 : index + 1)}.
                          </span>                          
                          <EasyEdit
                            type={Types.TEXTAREA}
                            onSave={handleUpdateOwnQuestion}
                            onCancel={cancel}
                            saveButtonLabel="Save"
                            cancelButtonLabel="Cancel"
                            value = {(ownQuestionCurrentValue && ownQuestionCurrentValue[index] !== "" && ownQuestionCurrentValue[index] !== undefined)
                              ? ownQuestionCurrentValue[index] : item }
                            onFocus = {(e) => {ownQuestionChange(e, index)}}
                            saveButtonStyle = "easy-edit-button"
                            saveOnBlur = {true}
                          /> 
                        </ListItem>
                    </List>
                ))}               
              </FormGroup>             
            </Container>

            <Container sx={{ width: "100%", mb: 0, mt: 3 }} className="add_question" style={{padding : '0'}}>
              <TextField                
                fullWidth
                type="text"
                onChange={(e) => setCurrentExtraQuestions(e.target.value)}
                label="Add your Question"
                margin="normal"
                variant="outlined"
                value={ currentExtraQuestions }
                sx={{ width: "95.2%", mt: 2 }}
              /> 

              <IconButton
                aria-label="close"
                onClick={handleAddExtraQuestion}
                sx={{
                  top: 15,
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <AddBoxRoundedIcon fontSize = "large"/>
              </IconButton>
            </Container>

              <Container sx={{ width: "100%", my: 2 }} style={{padding: '0'}}>
                <FormGroup>
                  {extraQuestionsData && extraQuestionsData.length > 0 && extraQuestionsData.map((item , codeIndex) => (
                    <List
                      sx={{
                            listStyleType: 'disc',
                            listStylePosition: 'inside',
                            display: 'flex',
                            flexDirection: 'row',
                            padding: 0
                          }}
                        className="extra_questions">
                        <ListItem disablePadding={true} key = {"extra"+codeIndex}>                             
                          {item}
                        </ListItem>
                        <ListItem key = {"extradel"+codeIndex}>
                        <IconButton
                              aria-label="close"
                              sx={{
                                top: -10,
                                color: "#cd5959"
                              }}
                              onClick={() => {handleCloseExtraQuestion(codeIndex)}}
                            >
                              <CancelPresentationIcon fontSize = "large" />
                            </IconButton>
                        </ListItem>
                    </List>
                  ))}               
                  </FormGroup>             
                </Container>

                <Button
                color="success"
                variant="contained"
                sx={{  mt: "10px" , width : "150px"}}
                className="buttonTheme mobile_full"
                type="submit"
                onClick={saveChanges}
              >
                Save
                { loading &&  <CircularProgress style={{'color': '#fff'}}/> }
              </Button>
            </div>
            
          </Container>
        </Box>
      </BootstrapDialog>
      
    </>
  );
};

export default TestDetails;

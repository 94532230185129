import { useState, useRef } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Container, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import axios from "axios";
import * as Constants from "../../constants";
import { useSelector ,useDispatch} from 'react-redux';
import { updateProfilePicture } from '../../actions/login';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const UplaodProfilePicture = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const loginUser = useSelector(state => state.loginReducer);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {

    var bodyFormData = new FormData();
    bodyFormData.append('profile', selectedFile);
    bodyFormData.append('uid', loginUser.identityUserData.localId); 
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    axios
      .post(Constants.APIURL + "api/v1/upload/profile/picture",bodyFormData ,config)
      .then((response) => {
            const new_user_data = loginUser.identityUserData;
            new_user_data.profilePicture =  response.data.json[1].photoURL;
 
            dispatch(updateProfilePicture(new_user_data));
            handleClose();
        })
        .catch((err)=>console.log(err));
    
  };

  const fileInput = useRef(null);

  const [previewUrl, setPreviewUrl] = useState("");
  const handleFile = (file) => {
    setSelectedFile(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let imageFile = e.dataTransfer.files[0];
    setSelectedFile(imageFile);
    setPreviewUrl(URL.createObjectURL(imageFile));
  };

  const removeImage = (e) => {
    e.preventDefault();
    setPreviewUrl(undefined);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="profile-btn"
        color="primary"
        fullWidth
        variant="text"
      >
        Upload Picture
      </Button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            fontSize: "1.5rem",
            fontWeight: "700",
            color: (theme) => theme.palette.primary.main,
            borderBottom: "3px solid #D1D5DB",
          }}
        >
          Upload Picture
        </BootstrapDialogTitle>
        <DialogContent>
          <Container sx={{ width: "800px", my: 4 }}>
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                paddingLeft: "1rem",
                fontSize: "0.75rem",
                marginTop: "1rem",
                marginBottom: "0.3rem",
              }}
            >
              Profile Picture
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#F1F1F1",
                p: 2,
                cursor: "pointer",
              }}
            >
              {previewUrl && (
                <Button onClick={(e) => removeImage(e)}>Remove</Button>
              )}
              {previewUrl && <img src={previewUrl} height={80} alt="profile" />}
              <section>
                <div
                  className="drop_zone"
                  onDrop={(e) => handleDrop(e)}
                  onDragOver={(e) => handleDragOver(e)}
                  onClick={() => fileInput.current.click()}
                >
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInput}
                    hidden
                    onChange={(e) => handleFile(e.target.files[0])}
                  />
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "primary.main",
                      fontSize: "22px",
                      fontWeight: 500,
                    }}
                  >
                    <AttachFileIcon fontSize="medium" />
                    <span
                      style={{
                        fontWeight: "700",
                        paddingLeft: "10px",
                        paddingRight: "5px",
                      }}
                    >
                      Drop
                    </span>
                    Image here or
                    <span
                      style={{
                        fontWeight: "700",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      Click
                    </span>
                    here to upload
                  </Typography>
                  <Box sx={{ mt: 1, color: "#A7A7A7", textAlign: "center" }}>
                    <Typography sx={{ fontSize: "14px" }}>
                      Supported Files: JPG, PNG
                    </Typography>
                    {/*<Typography sx={{ fontSize: "14px", paddingTop: "0px" }}>
                      Supported Resolution: 1358 x 180 Px
                    </Typography>*/}
                    <Typography sx={{ fontSize: "14px", paddingTop: "0px" }}>
                      Please upload a square image (ex: 180px X 180px)
                    </Typography>
                  </Box>
                </div>
              </section>
            </Box>
          </Container>
        </DialogContent>
        <DialogActions sx={{ borderTop: "3px solid #D1D5DB" }}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={3}>
              <Button
                color="success"
                variant="contained"
                onClick={onSubmit}
                sx={{
                  px: 20,
                  my: "8px",
                  fontSize: "15px",
                }}
                className="buttonTheme"
              >
                SAVE
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

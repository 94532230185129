import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Container, TextField, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Constants from "../../constants";
import { Alert } from "../alert";
import CircularProgress from "@mui/material/CircularProgress"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const AddCandidate = (props) => {
  const [alert, setAlert] = useState({ open: false, data: "" });
  const [loading, setLoading] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    firstname: "",
    lastname : "",
    username : ""
  });
  const [validation, setValidation] = useState({
    firstname: "",
    lastname : "",
    username : ""
  });
  
  
  const handleChange = (event) => {
    const { name, value } = event.target;
      setFieldValues((prev) => ({
        ...prev,
        [name]: value
      }));
  };

  const { firstname, lastname, username} = fieldValues

  const validate = () => {
    let errors = { ...validation };
    let result = true;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const textRegex = /^[a-zA-Z][\w. ]*$/; // Updated regex for letters and spaces
    
    if (!firstname.trim()) {
      result = false;
      errors.firstname = "Please enter first name";
    } else if (!textRegex.test(firstname)) {
      result = false;
      errors.firstname = "Please enter valid first name";
    } else {
      errors.firstname = "";
    }
  
    if (!lastname.trim()) {
      result = false;
      errors.lastname = "Please enter last name";
    } else if (!textRegex.test(lastname)) {
      result = false;
      errors.lastname = "Please enter valid last name";
    } else {
      errors.lastname = "";
    }
  
    if (!username.trim()) {
      result = false;
      errors.username = "Please enter email";
    } else if (!emailRegex.test(username)) {
      result = false;
      errors.username = "Please enter valid email address";
    } else {
      errors.username = "";
    }
  
    setValidation(errors);
    return result;
  };
  

  const handleSubmit = (e) => {
   e.preventDefault();
   if (validate()) {
    var data = {
    "firstName" : fieldValues.firstname,
    "lastName" : fieldValues.lastname,
    "email" : fieldValues.username,
    "testId" : props.testID
    }
    setLoading(true)
    var JwtToken = localStorage.getItem("JwtToken");

    props.testID &&
      axios
        .post(
          Constants.APIURL + "candidate",
          data,
          {
            headers: {
              'Authorization': `${JwtToken}`
            }}
        )
        .then((response) => {               
          if (response.data.status !== 200) {
            setAlert({ open: true, data: "The Candidate has been added successfully." });
            setLoading(false)
            props.setTestID(""); 
            setTimeout(() =>{
              props.close();  
            }, 1000)        
             
          }       
        }).catch((error) => {
          setLoading(false)
          console.log(error);
          setAlert({ open: true, data: "The candidate already exists." });
      });
    }
  };
  return (
    <>
      <Alert
        open={alert.open}
        data={alert.data}
        close={() => setAlert({ ...alert, open: false })}
      />
      <BootstrapDialog
        onClose={props.close}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth="lg"
        className="mobile_modal_content">
        <form onSubmit={handleSubmit}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.close}
            sx={{
              fontSize: "1.5rem",
              fontWeight: "700",
              color: (theme) => theme.palette.primary.main,
              borderBottom: "3px solid #D1D5DB",
            }}
          >
            Add Candidate to Test {props.testname && props.testname.length >0 && props.testname.join(",")}
          </BootstrapDialogTitle>
          <DialogContent>        
            <Container sx={{ width: "800px", my: 2 }}>
              <TextField
                fullWidth
                type="text"
                label="First Name"
                margin="normal"
                variant="outlined"
                name="firstname"
                value={fieldValues.firstname}
                onChange={handleChange}
              />
               {validation.firstname && <p className='error'>{validation.firstname}</p>}
            </Container>
            <Container sx={{ width: "800px", my: 2 }}>
              <TextField
                fullWidth
                type="text"
                label="Last Name"
                margin="normal"
                variant="outlined"
                name="lastname"
                value={fieldValues.lastname}
                onChange={handleChange}
              />
               {validation.lastname && <p className='error'>{validation.lastname}</p>}
            </Container>
            <Container sx={{ width: "800px", my: 2 }}>
              <TextField
                fullWidth
                type="text"
                label="Email Id"
                margin="normal"
                variant="outlined"
                name="username"
                value={fieldValues.username}
                onChange={handleChange}
              />
              {validation.username && <p className='error'>{validation.username}</p>}
            </Container>
          </DialogContent>
          <DialogActions sx={{ borderTop: "3px solid #D1D5DB" }}  className="modal_footer">
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={3}>
                <Button
                  color="success"
                  variant="contained"
                  sx={{ px: 20, margin: "0.8rem" }}
                  type="submit"
                  className="buttonTheme"
                >
                  Add
                  { loading &&  <CircularProgress style={{'color': '#fff'}}/> }
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </>
  );
};

export default AddCandidate;

import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { UplaodProfilePicture } from "./UploadProfilePicture";
import { SetNewPassword } from "./SetNewPassword";
import UserImage from "../../assets/images/dummyuser.png";
import { useSelector } from 'react-redux';
import profileImg from "./avatar6.png";
export const Profile = (props) => {  
  return (
    <Card {...props} className="profile-and-pwd">
      <CardContent style={{ paddingBottom: "10px" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            src={profileImg}
            sx={{
              height: 64,
              mb: 2,
              width: 64,
            }}
          />

          <Typography color="textPrimary" gutterBottom variant="h5">
            {localStorage.getItem("firstName") + " " + localStorage.getItem("lastName")}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      {/* <CardActions>
        <UplaodProfilePicture />
      </CardActions>
      <Divider />
      <CardActions>
        <SetNewPassword />
      </CardActions> */}
    </Card>
  );
};

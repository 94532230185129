import React, { useState } from "react";
import { PDFExport } from '@progress/kendo-react-pdf';
import { DashboardLayout } from "../components/dashboard-layout";
import { Box, Container, Button} from "@mui/material";
import ReportDocument from "./reportDocument";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
function CandidateReport() {
  const [candidateData, setCandidateData] = useState([])
  const pdfExportComponent = React.useRef(null);

  const getCandidateData = (data) => {
    setCandidateData(data);
  };

  return (
  <>
    <DashboardLayout>
    <Container>
      <Box  component="main"
          sx={{
            flexGrow: 1,
            pt: 3,
            mt: 2,
          }}>
        <Button
            color="success"
            variant="contained"
            sx={{ padding: '10px 20px', my: "8px", float: "right !important" }}
            className="buttonTheme"
            onClick={() => {
              if (pdfExportComponent.current) {
                pdfExportComponent.current.save();
              }
            }}
          >
          <FileDownloadIcon />
          Download
        </Button>
      </Box>
      <Box  component="main"
        sx={{
          flexGrow: 1,
          pt: 3,
          mt: 2,
        }}>
      
        <PDFExport paperSize="A3" margin="2cm" ref={pdfExportComponent} fileName={`${candidateData.firstName}${candidateData.lastName && candidateData.lastName}_Report.pdf`}>
          <ReportDocument setCadData={getCandidateData}/>       
        </PDFExport>
      </Box>
    </Container>
    </DashboardLayout>
    </>
  )
};
export default CandidateReport;
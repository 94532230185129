import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "../setup/services/fetch.service";
// import { profileApi } from "../setup/services/profiles.service";
import authReducer from "./authslice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    // profile:profileReducer,
    [authApi.reducerPath]: authApi.reducer,
    // [profileApi.reducerPath]: profileApi.reducer,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
setupListeners(store.dispatch);

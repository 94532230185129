import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Container, TextField, Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import axios from "axios";
import * as Constants from '../../constants';
import CircularProgress from "@mui/material/CircularProgress"
import { Alert } from "../alert";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteCandidate from "./DeleteCandidate";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const ViewCandidates = (props) => {
  const [alert, setAlert] = useState({ open: false, data: "" });
  const [tableRows, setTableRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState([]);
  const [loading, setLoading] = useState(false);
  let [testNameValue, setTestNameValue] = useState("");
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openTestDetailsPopup, setOpenTestDetailsPopup] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  let [deleteID, setDeleteID] = useState("");
  let [testid, setTestID] = useState(props.testid);
  let [finalResultData, setFinalResultData] = useState([]);

  const [selectedViewDetails, setSelectedViewDetails] = useState({
    "programmingLanguage" : [],
    "questions" : [],
    "id" : ""
  });

  const closeTestDetailsPopup = () => {
    setOpenTestDetailsPopup(false);
  };

  const viewTestDetails =  React.useCallback(
    (programmingLanguage, questions, id) => () => {
      var data = {
        "programmingLanguage" : programmingLanguage,
        "questions" : (questions && questions.question && questions.question.length > 0)  ? questions.question : [],
        "id" : id
      }
      var temp = {...selectedViewDetails}
      temp = data;
      setOpenTestDetailsPopup(true);
      setSelectedViewDetails(temp)    
    }
   );

  const closeDeletePopup = () => {
    setOpenDeletePopup(false);
  };

  let resultData = [];
  var JwtToken = localStorage.getItem("JwtToken");
  const deleteCandidate = React.useCallback(
    (id) => () => {
      setDeleteID(id);
      setOpenDeletePopup(true);      
    },
    []
  );

  const handleViewDetails = (e) => {
    e.preventDefault();
    localStorage.setItem("viewCandidateName", e.target.dataset.name);
    window.location.href = "questionAnswers/" + e.target.dataset.id;
  };

  const viewCandidateReport = (e) => {
    e.preventDefault();
    localStorage.setItem("viewCandidateName", e.target.dataset.name);
    window.location.href = "candidateReport/" + e.target.dataset.id;
  };

  const getName = (fName, lName) => {
    return fName + " " + lName;
  }

  const columns = [
    {
      field: "Firstname",
      headerName: "First Name",
      minWidth: 110,
      flex: 1,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      minWidth: 110,
      flex: 1,
    },
    {
      field: "username",
      headerName: "Email Id",
      minWidth: 180,
      flex: 1,
    },  
    {
      field: "interviewStatus",
      headerName: "Interview Status",
      minWidth: 110,
      flex: 1,
    },
    {
      field: "score",
      headerName: "Score",
      minWidth: 30,
      maxWidth: 80,
      flex: 1,
    },
    {
      field: "view",
      headerName: "Interview Details",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <a href = "" data-id = {params.id} data-name = { getName(params.row.Firstname, params.row.lastname) } onClick = {handleViewDetails}>Interview Details</a>
      ),
    },
    {
      field: "",
      headerName: "View Report",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        ( params.row.interviewStatus === "Completed" &&   
        <a href = "" data-id = {params.id} data-name = { getName(params.row.Firstname, params.row.lastname) } onClick = {viewCandidateReport}>View Report</a>
        )
      ),
    },
    {
      field: "created_by",
      headerName: "Created By",
      minWidth: 100,
      flex:2,
    },
    {
      field: "created_date",
      headerName: "Created On",
      minWidth: 110,
      flex: 1,
      accessor: "created_date",
      renderCell: (row) => (
        <span>{moment(row.value).format("MM/DD/YYYY")}</span>
      ),
    },
    {
      field: "actions",
      type: "actions",
      maxWidth: 50,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon sx={{ color: "#B7345C" }} />}
          label="Delete"
          sx={{ color: "#B7345C" }}
          onClick={deleteCandidate(params.id)}
          showInMenu
        />       
      ],
    },
  ];


  const getCandidates = () => {
    setLoading(true);
    axios
      .get(
        Constants.APIURL + "getCandidatesByTest/" + props.testid, {
          headers: {
            'Authorization': `${JwtToken}`
          }}
      )
      .then((response) => {
          setLoading(false);
          var tests = response.data.map((item) => {
            return {
              id: item.id,       
              Firstname: item.firstName,
              lastname: item.lastName,
              username: item.email,
              questions: item.AIQuestion,
              view: "view details",
              score : ( item.Results.length ? item.Results[0].result : 0 ),
              interviewStatus: item.interviewStatus ?  ( item.Results.length > 0 ? "Completed" : "Started" ) : "Incomplete",        
              created_by: item.user.firstName + " " + item.user.lastName,
              test_name : (item.Test.programmingLanguage.length > 0 ? item.Test.programmingLanguage.toString() : ""),
              created_date: item.createdAt
            } 
        });

        setTableRows(tests);
        setLoading(false);
        if (tests.length > 10) {
          setRowsPerPage([10, 25, 50, 100]);
        }       
      });
  }

  useEffect(() => {
    setTestID(props.testid);
  },[props.testid]);

  useEffect(() => {
    if (testid) {      
      setTableRows([])
      localStorage.setItem("viewCandidateName", "");
      getCandidates();
      console.log("tableRows", tableRows);
    }
  },[testid]);

  return (
    <>
      <Alert
        open={alert.open}
        data={alert.data}
        close={() => setAlert({ ...alert, open: false })}
      />
      <BootstrapDialog
        onClose={props.close}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth="lg"
        className="view-candidates-parent"
      >
        <DeleteCandidate open={openDeletePopup} close={closeDeletePopup} deleteID={deleteID} setDeleteID={setDeleteID} propsclose = {props.close} />        
        <Box
          component="main"
          sx={{
            flexGrow: 1,
          }}
          className = "view-candidates"
        >
        <Container maxWidth={false}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.close}
            sx={{
              fontSize: "1.5rem",
              fontWeight: "700",
              color: (theme) => theme.palette.primary.main,
              borderBottom: "3px solid #D1D5DB",
              paddingLeft: 0,
              paddingRight: 0,
              textTransform: 'capitalize'
            }}
          >
          {props.testname && props.testname.length > 0 && props.testname.join(",").toLowerCase()} Candidates List
          </BootstrapDialogTitle>
            <div
              style={{
                width: "100%",
                marginTop: "24px",
                backgroundColor: "white",
              }}
              className="shrink_table"
            >
              <Box sx={{ height: 400, width: '100%' }} className="center_port">
              {loading?  <CircularProgress /> : tableRows &&
                <DataGrid
                  rows={tableRows.filter(res=>res !== null)}
                  columns={columns}                  
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  pageSize={pageSize}
                  rowsPerPageOptions={rowsPerPage}
                  disableColumnSelector
                  sortingOrder={['asc', 'desc']}
                  hideFooter={tableRows.length < 0 ? true: false}
                />
              }
                </Box>
             
            </div>
            
          </Container>
        </Box>
      </BootstrapDialog>
      
    </>
  );
};

export default ViewCandidates;

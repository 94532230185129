import React from "react";
import { useGetLanguageQuery } from "../setup/services/fetch.service";

type LanguageData = {
  language: string;
};

type LanguageDataProps = LanguageData & {
  updateFields: (fields: Partial<LanguageDataProps>) => void;
};
export default function Language({
  language,
  updateFields,
}: LanguageDataProps) {
  const { data, error, isLoading, isSuccess } = useGetLanguageQuery("");

  // const mplogoImg = require("../images/mplogo.png");
  const mplogoImg = "";
  return (
    <div className="container ">
      <div className="text-center mt-2 innerDivOpt">
        

        <div className="primaryRoleOptList  ">
        <span className="d-block imglogo  mb-5 ">
            <img src={mplogoImg} className="w-100"/> 
          </span>
          <div className=" plans d-inline-grid  justify-content-around gx-5">

          <h3 className="mt-2 mb-4 fw-bold titletxtRole">
          Select your Coding Language
        </h3>
        <div className="optionList">
            {error && <h2 className="loadMsgTxt">Something went wrong</h2>}
            {isSuccess && (
              <> 
                {data?.map((language: any) => (
                  <span  key={language}>
                   <label>
                      <input type="radio"
                      name="roleId"
                      required
                      value={language}
                      onChange={(e: any) =>
                        updateFields({ language: e.target.value })
                      } />
                      <span className="yes">{language}</span>
                  </label>
               </span>
                ))}
                   <label
                    className="plan m-2 basic-plan d-none"
                    htmlFor={language}
                    key={language}
                  >
                    <input
                      type="radio"
                      name="roleId"
                      required
                      value={language}
                      onChange={(e: any) =>
                        updateFields({ language: e.target.value })
                      }
                    />

                    <div className="plan-content">
                      <div className="plan-details">
                        <p>{language}</p>
                      </div>
                    </div>
                  </label>
              </>
            )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

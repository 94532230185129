import { FormEvent, useState, useEffect } from "react";
import { useMultistepForm } from "../components/useMultistepForm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../store/hooks";
import { useGoogleSignupUserMutation } from "../setup/services/fetch.service";
import { selectAuth } from "../store/authslice";
import Language from "../components/language";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from "axios";
import * as Constants from '../constants';

type FormData = {
  language: string;
};

const INITIAL_DATA: FormData = {
  language: "",
};

function GoogleAuthWrapper() {
  const [data, setData] = useState(INITIAL_DATA);
  const navigate = useNavigate();
  const [
    googlesignUpUser,
    {
      data: googleSignUpData,
      isSuccess: issignupSuccess,
      isError: issignupError,
    },
  ] = useGoogleSignupUserMutation();

  const { firstName, lastName, token, email } = useAppSelector(selectAuth);
  const { language } = data;

  useEffect(() => { 
    if (issignupSuccess) {
      localStorage.setItem(
        "user",
        JSON.stringify({
          firstName: googleSignUpData?.firstName,
          lastName: googleSignUpData?.lastName,
          email: googleSignUpData?.email,
          language: googleSignUpData?.language,
        })
      );
      toast.success("Thanks for signing up.", {
        position: toast.POSITION.TOP_RIGHT,
      }); 
       // Add User to table
      // addUser();
      
    }

    if (issignupError) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [issignupSuccess, issignupError]);

  function updateFields(fields: Partial<FormData>) {
    setData((prev) => {
      return { ...prev, ...fields };
    });
  }
  const { step, isFirstStep, isLastStep, next } = useMultistepForm([
    <Language {...data} updateFields={updateFields} />,
  ]);

  async function onGoogleAuthSubmit(e: FormEvent) {
    e.preventDefault();
    if (!isLastStep) return next();

    await googlesignUpUser({
      firstName,
      lastName,
      token,
      email,
      language,
    });   
  }

  const addUser = () => {
    console.log("Add User")
    var userEmail = localStorage.getItem("email");
    console.log(userEmail)
    var data = {
      "username" : userEmail,
      "password" : "@MPchatgpt!@#$",
      "firstname" : localStorage.getItem("firstName"),
      "lastname" : localStorage.getItem("lastName"),
    }
    axios
      .post(
        Constants.APIURL + "users/" ,data)
      .then((response) => {
        console.log(response);   
        navigate("/tests");    
      }).catch((err) =>
      console.log(err));    
  }

  return (
    <div className="container-login100">
      <form
        onSubmit={onGoogleAuthSubmit}
        name="googlesignupSubmit"
        id="googlesignupSubmit"
        className="needs-validation box"
        noValidate
      >
        {step}
        <div className="container">
          <div className={"SignUpSubmitBtn show"}>
            <button type="submit" className="btn text-center btnStylingsubmit ">
              {isLastStep ? "Submit" : isFirstStep ? "Next" : "Next"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default GoogleAuthWrapper;

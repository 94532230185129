import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Button, ListItem } from "@mui/material";

export const NavItem = (props) => {
  const { href, icon, title, subPath, ...others } = props;
  const navigate = useNavigate();
  const location = useLocation();
  let active = false;
  if (subPath && subPath.includes(location.pathname)) {
    active = true;
  } else {
    active = location.pathname === href;
  }

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        mb: 0.5,
        py: 0,
        px: 2,
      }}
      {...others}
    >
      <Button
        component="a"
        startIcon={icon}
        disableRipple
        onClick={() => {
          navigate(href);
        }}
        sx={{
          mb: 1,
          backgroundColor: active && "#F1F1F1",
          borderRadius: 1,
          color: "black",
          fontWeight: active ? "fontWeightBold" : "500",
          justifyContent: "flex-start",
          px: 3,
          textAlign: "left",
          textTransform: "none",
          width: "100%",
          "& .MuiButton-startIcon": {
            color: active ? "#2BCC70" : "#30687B",
          },
          "&:hover": {
            backgroundColor: "#F1F1F1",
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
};

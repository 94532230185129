import React from "react";
import {
  Box,
  Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddTest from "./AddTest";

export const TestToolbar = (props) => {
  return (
    <Box {...props}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          mb: 2,
        }}
      >
        <Typography sx={{ m: 1, color: "#30687B" }} variant="h4">
          Test Management
        </Typography>
        <Box sx={{ m: 1, display: "flex" }}>
          <Box sx={{ maxWidth: 380, mr: 3 }}>
            {/* <FormControl sx={{ m: 1, minWidth: 380 }}>
              <TextField
                fullWidth
                label="Search"
                onChange={(e) => props.search(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="medium" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
                variant="outlined"
              />
            </FormControl> */}
          </Box>
          <AddTest />
        </Box>
      </Box>
    </Box>
  );
};

export default TestToolbar;

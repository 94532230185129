import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import * as Constants from "../../constants";
import { Alert } from "../alert";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const DeleteCandidate = (props) => {
  const [alert, setAlert] = useState({ open: false, data: "" });
  const data = {
    data: {
      id: props.deleteID,
    },
  };

  const onSubmit = () => {
    var JwtToken = localStorage.getItem("JwtToken");
    console.log("Delete Id", data);
    props.deleteID &&
      axios
        .delete(Constants.APIURL + "candidate/"+ props.deleteID, 
        { 
          headers: {
          'Authorization': `${JwtToken}`
        }})
        .then((response) => {
          props.setDeleteID("");
          if (response.data.status !== 200) {
            setAlert({ open: true, data: "The candidate has been deleted successfully." });
            // props.propsclose();
            window.location.reload();
          }
        });
    props.close();
  };

  return (
    <>
      <Alert
        open={alert.open}
        data={alert.data}
        close={() => setAlert({ ...alert, open: false })}
      />
      <BootstrapDialog
        onClose={props.close}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.close}
          sx={{
            fontSize: "1.5rem",
            fontWeight: "700",
            color: (theme) => theme.palette.primary.main,
            borderBottom: "3px solid #D1D5DB",
          }}
          className="alert_popup" 
        >
          Delete Candidate
        </BootstrapDialogTitle>
        <DialogContent>
          <Container sx={{ width: "600px" }}>
            <Typography
              sx={{
                color: "black",
                fontWeight: 500,
                fontSize: "1.2rem",
                mt: 2,
              }}
            >
              Are you sure you want to delete ?
            </Typography>
          </Container>
        </DialogContent>
        <DialogActions sx={{ padding: "1rem !important" }}>
          <Button onClick={props.close} sx={{ color: "black", px: 5 }}>
            Cancel
          </Button>
          <Button
            color="error"
            type="submit"
            variant="contained"
            onClick={onSubmit}
            sx={{ px: 5 }}
            className="buttonTheme"
          >
            Delete
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default DeleteCandidate;

import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {
    AppBar,
    Box,
    IconButton,
    Toolbar,
    Avatar,
    Typography,
    Menu,
    MenuItem,
    ListItemText,
    Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "./../assets/images/logo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { setIsAuthenticated, setLoginMode, setIdentityUser, setSsoUser, setAccountId } from "../actions/login";
import profileImg from "./avatar6.png";
const DashboardNavbarRoot = styled(AppBar)(() => ({
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(100, 116, 139, 0.12)",
}));
export const DashboardNavbar = (props) => {
    const { onSidebarOpen, ...other } = props;
    
    const [anchorElUser, setAnchorElUser] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logout = () => {        
        window.localStorage.removeItem("user", {});
        // dispatch(setIsAuthenticated(false));
        navigate("/tests");
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    return (
        <>
            <DashboardNavbarRoot {...other}>
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 50,
                        left: 0,
                        px: 2,
                        py: 1,
                    }}
                    className="buttonTheme"
                >
                <Box sx={{ flexGrow: 0 }} />                
                    <Box sx={{ mr: 4 }}>
                        <img src={Logo} height="100px" alt="Open AI logo" />
                    </Box>
                    <Typography
                        sx={{flexGrow: 35 }}
                        style={{ color: "black", fontWeight: "bold" }}
                    >
                    </Typography>
                    <Box
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                    }}
                    >

                    <Typography color="#fff" gutterBottom variant="h6">
                        {localStorage.getItem("firstName") + " " + localStorage.getItem("lastName")}'s Dashboard
                    </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar
                                sx={{
                                    height: 40,
                                    width: 40,
                                    ml: 2,
                                }}
                                src={profileImg}
                            >
                                <AccountCircleIcon fontSize="small" />
                            </Avatar>
                            
                        </IconButton>
                        <Menu
                            className="dropdownUser"
                            sx={{
                                minWidth: "268px!important",
                                minHeight: "179px!important",
                                overflow: "hidden",
                                borderRadius: "4px",
                                top: "60px",
                            }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem
                                className="profileDropdown"
                                onClick={() => {
                                    navigate("/profile");
                                }}
                            >
                                <Typography textAlign="center">Profile</Typography>
                            </MenuItem>
                            <Divider />
                            <MenuItem className="profileDropdown" onClick={() => logout()}>
                                <Typography textAlign="center">Log out</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    
                    <IconButton
                        onClick={onSidebarOpen}
                        sx={{
                            ml: 2,
                            display: {
                                xs: "inline-flex",
                                lg: "inline-flex",
                            },
                            color : "#fff"
                        }}
                    >
                        <MenuIcon sx={{ fontSize: "2rem" }} />
                    </IconButton>
                </Toolbar>
            </DashboardNavbarRoot>
        </>
    );
};

DashboardNavbar.propTypes = {
    onSidebarOpen: PropTypes.func,
};
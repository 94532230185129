import React, { useEffect, useState } from "react";
import { Box, Container, ListItem, List, Typography, ListItemButton,ListItemIcon , ListItemText} from "@mui/material";

import { Line, Circle } from 'rc-progress';
import ReactSpeedometer from "react-d3-speedometer"
import axios from "axios";
import * as Constants from '../constants';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

function ReportDocument({setCadData}) {
  const [reportData, setData] = useState([]);
  const [interviewDetailsData, setInterviewDetailsData] = useState([]);
  const [loading, setLoadingFlag] = useState(true);
  const [CandidateID, setCandidateID] = useState("");
  const cId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  

  const getData = () => {  
    var JwtToken = localStorage.getItem("JwtToken");
    axios
    .get(
      Constants.APIURL + "candidateResultDetails/" + CandidateID, {
        headers: {
          'Authorization': `${JwtToken}`
        }}
    )  .then((response) => {
      setData(response.data.candidate)
      setCadData(response.data.candidate)
      setInterviewDetailsData(response.data.interviewDetails)
      
    });
  }

  useEffect(() => {
    setData([])
    setInterviewDetailsData([])
    setCandidateID(cId);   
  },[cId]);

  useEffect(() => {
    if(CandidateID !== ""){
        getData();
      
    }   
  },[CandidateID]);

  useEffect(() => {
    console.log("reportData");
    console.log(reportData);
    if(reportData && interviewDetailsData){
      // console.log("reportData");
      // console.log(reportData);
      setLoadingFlag(false);
    }   
  },[reportData, interviewDetailsData]);


  const getDateFormat = (date)  => {
    var d = new Date(date)
    const monthIndex = d.getMonth()
    const monthName = months[monthIndex]
    const day = d.getDate()
    const year = d.getFullYear()
    return day + "-" + monthName + "-" + year
  }

  const getScorePercentage = (interviewDetailsData, reportDetails) => {
    var percentageOfScore = 0;
    if(reportDetails && reportDetails.Test){
      var totalNoOfQuestions = reportDetails.Test.numberOfSelectedQuestions;
      var totalSore = totalNoOfQuestions * 10; 
      var overallScore = getTotalScore(interviewDetailsData)
      percentageOfScore = ((overallScore * 100 ) / totalSore ).toFixed(2);
    }
    return percentageOfScore;
  }

  const getGrade = (interviewDetailsData, reportDetails) => {   
      var percentageOfScore = getScorePercentage(interviewDetailsData, reportDetails)
      var  grade = "BEGINNER"
      if(Math.round(percentageOfScore) <= 25){
        grade = "BEGINNER"
      }
      else if(Math.round(percentageOfScore) > 25 && Math.round(percentageOfScore) <= 50){
        grade = "INTERMEDIATE"
      }
      else if(Math.round(percentageOfScore) > 50 && Math.round(percentageOfScore) <= 75){
        grade = "EXPERIENCED"
      } 
      else if(Math.round(percentageOfScore) > 75 && Math.round(percentageOfScore) <= 100){
        grade = "PROFICIENT"
      }
      return grade

  }


  const getTotalScore = (interviewDetails) => {
    if(interviewDetails){
      var totalScore = 0
      interviewDetails.map((a) => {
        if(parseInt(a.score) !== 0)
        totalScore += parseInt(a.score)   
      })
      return totalScore;
    }
  }

  const getAdminQuestionsNos = (reportDetails)  => {
    var adminQuestionNos = 0;
    if(reportDetails && reportDetails.Test){
      var conceptQuestions = reportDetails.Test.numberOfQuestions;
      var codingQuestions = reportDetails.Test.numberOfCodingQuestions;
      var totalConceptQuestions =  ( conceptQuestions && conceptQuestions.length > 0 ? conceptQuestions.reduce((a, b) => parseInt(a) + parseInt(b)) : 0)
      var totalCodingQuestions = (codingQuestions && codingQuestions.length > 0 ? codingQuestions.reduce((a, b) => parseInt(a) + parseInt(b)) : 0 )
      var totalNoOfQuestions = reportDetails.Test.numberOfSelectedQuestions;
      var adminQuestionNos = totalNoOfQuestions - (totalConceptQuestions + totalCodingQuestions)
    }
    return adminQuestionNos;
  }

  const getAdminQuestionsPercentage = (interviewDetails, reportDetails)  => {
    var questionNos = getAdminQuestionsNos(reportDetails)
    var totalSoreObtained = getAdminQuestionsScore(interviewDetails, reportDetails)
    var totalSore = questionNos * 10;
    var percentageOfScore = (totalSoreObtained * 100 ) / totalSore;
    return percentageOfScore.toFixed(2);    
  }  

  const getAdminScore = (reportDetails, adminQuestionNos, interviewDetails) => {    
    
    var totalNoOfQuestions = reportDetails.Test.numberOfSelectedQuestions
    var adminQuestionStartIndex = totalNoOfQuestions - adminQuestionNos;

    console.log("adminQuestionNos", adminQuestionNos);
    console.log("adminQuestionStartIndex", adminQuestionStartIndex)
    let questionsAnswers = interviewDetails.slice(0);
    var chunkQuestionAnswers = questionsAnswers.splice(adminQuestionStartIndex , adminQuestionNos);

    console.log("chunkQuestionAnswers");
    console.log(chunkQuestionAnswers)
    var totalScore = 0;

    chunkQuestionAnswers.map((b, index) => {
      console.log("admin score",b.score)
      totalScore += parseInt(b.score)   
    })
    console.log("totaladminScore");
    console.log(totalScore);
    return totalScore    
  }

  const getAdminAnswered = (interviewDetails, reportDetails, wrongAnswersFlag) => {    
    var adminQuestionNos = getAdminQuestionsNos(reportDetails)
    var totalNoOfQuestions = reportDetails.Test.numberOfSelectedQuestions
    var adminQuestionStartIndex = totalNoOfQuestions - adminQuestionNos;

    console.log("adminQuestionNos", adminQuestionNos);
    console.log("adminQuestionStartIndex", adminQuestionStartIndex)
    let questionsAnswers = interviewDetails.slice(0);
    var chunkQuestionAnswers = questionsAnswers.splice(adminQuestionStartIndex , adminQuestionNos);

    console.log("chunkQuestionAnswers");
    console.log(chunkQuestionAnswers)
    var totalAnswered = 0;

    chunkQuestionAnswers.map((a, index) => {
      if(wrongAnswersFlag){
        if(parseInt(a.score) === 0){
          totalAnswered += 1
        }
      }
      else{
        if(parseInt(a.score) !== 0){
          totalAnswered += 1
        }
      }    
    })
    console.log("totalAnswered");
    console.log(totalAnswered);
    return totalAnswered    
  }
  
  const getAdminTimeTaken = (reportDetails, adminQuestionNos, interviewDetails) => {    
    var totalNoOfQuestions = reportDetails.Test.numberOfSelectedQuestions
    var adminQuestionStartIndex = totalNoOfQuestions - adminQuestionNos;

    console.log("adminQuestionNos", adminQuestionNos);
    console.log("adminQuestionStartIndex", adminQuestionStartIndex)
    let questionsAnswers = interviewDetails.slice(0);
    var chunkQuestionAnswers = questionsAnswers.splice(adminQuestionStartIndex , adminQuestionNos);

    console.log("chunkQuestionAnswers");
    console.log(chunkQuestionAnswers)
    var timeTaken = 0;

    chunkQuestionAnswers.map((b, index) => {
      console.log("admin timeTaken",b.timeTaken)
      timeTaken += parseInt(b.timeTaken)   
    })
    console.log("totaladminScore");
    console.log(timeTaken);
    return timeTaken    
  }

  const getAdminQuestionsTimeTaken = (interviewDetails, reportDetails ) => {
    var adminQuestionNos = getAdminQuestionsNos(reportDetails)   
    var totalScore = getAdminTimeTaken(reportDetails, adminQuestionNos, interviewDetails)
    return totalScore ;
  }

  const getAdminQuestionsScore = (interviewDetails, reportDetails) => {
    var adminQuestionNos = getAdminQuestionsNos(reportDetails)   
    var totalScore = getAdminScore(reportDetails, adminQuestionNos, interviewDetails)
    return totalScore ;
  }

  const getSectionTimeTaken = (index, interviewDetails, reportDetails) => { 
    if(reportDetails && reportDetails.Test){
      console.log("index", index);

      let cloneArrnumberOfQuestions = reportDetails.Test.numberOfQuestions.slice(0);

      var noOfquestions = cloneArrnumberOfQuestions[index];
      var languageIndex = 0

      if(index === 0 || index < (cloneArrnumberOfQuestions.length - 1)) {
        languageIndex =  index + 1
      }
      else
      {
        languageIndex =  index
      }

      var chunkArray = cloneArrnumberOfQuestions.splice(0, languageIndex)
      var startIndex = chunkArray.reduce((a, b) => parseInt(a) + parseInt(b))
      
      var chunkStartIndex = 0;
      if(index === 0){
        chunkStartIndex = 0;
      }
      else{
        chunkStartIndex =  startIndex
      }

      console.log(chunkStartIndex, "chunkStartIndex")
      console.log(noOfquestions, "noOfquestions" )
 
      var cloneinterviewDetails = interviewDetails.slice(0);      
      var chunkConceptQuestionAnswers = cloneinterviewDetails.splice(chunkStartIndex , noOfquestions);

      console.log("chunkConceptQuestionAnswers");
      console.log(chunkConceptQuestionAnswers)

      var totaltimeTaken = 0
      chunkConceptQuestionAnswers.map((a, index) => {
        console.log("timeTaken",a.timeTaken)
        totaltimeTaken += parseInt(a.timeTaken)   
      })
      console.log("timeTaken");
      console.log(totaltimeTaken);

      // Coding Score
      var cloneArrnumberOfCodingQuestions = reportDetails.Test.numberOfCodingQuestions.slice(0);
      var cloneArrnumberOfConceptQuestions = reportDetails.Test.numberOfQuestions.slice(0);

      
      
      var totalCodetimeTaken = 0

      if(cloneArrnumberOfCodingQuestions && cloneArrnumberOfCodingQuestions.length > 0 ){
        var numberOfCodingQuestions = cloneArrnumberOfCodingQuestions[index];
        var chunkCodingArray = []
        var previousCodingQuestions = 0
        
        if(index !== 0){
          chunkCodingArray = cloneArrnumberOfCodingQuestions.splice(0, languageIndex)
          previousCodingQuestions = chunkCodingArray.reduce((a, b) => parseInt(a) + parseInt(b))
        }
        var totalpreviousNoOfQuestions = 0

        if(cloneArrnumberOfConceptQuestions.length > 0){
          totalpreviousNoOfQuestions = cloneArrnumberOfConceptQuestions.reduce((a, b) => parseInt(a) + parseInt(b));
        }

        var codestartIndex = 0;
        if(index === 0){
          codestartIndex = totalpreviousNoOfQuestions;
        }
        else{
          codestartIndex =  (totalpreviousNoOfQuestions + previousCodingQuestions )
        }
      
        console.log(codestartIndex, "codestartIndex")
        console.log(numberOfCodingQuestions, "numberOfCodingQuestions" )


        let questionsAnswers = interviewDetails.slice(0);       

        var chunkQuestionAnswers = questionsAnswers.splice(codestartIndex , numberOfCodingQuestions);

        console.log("chunkQuestionAnswers");
        console.log(chunkQuestionAnswers)

        chunkQuestionAnswers.map((b, index) => {
          console.log("coding timeTaken",b.timeTaken)
          totalCodetimeTaken += parseInt(b.timeTaken)   
        })
        console.log("totalCodetimeTaken");
        console.log(totalCodetimeTaken);
      }
      return totaltimeTaken + totalCodetimeTaken ;
    }
  }
  const getSectionScore = (index, interviewDetails, reportDetails) => {
    if(reportDetails && reportDetails.Test){
      console.log("index", index);

      let cloneArrnumberOfQuestions = reportDetails.Test.numberOfQuestions.slice(0);

      var noOfquestions = cloneArrnumberOfQuestions[index];
      var languageIndex = 0

      if(index === 0 || index < (cloneArrnumberOfQuestions.length - 1)) {
        languageIndex =  index + 1
      }
      else
      {
        languageIndex =  index
      }

      var chunkArray = cloneArrnumberOfQuestions.splice(0, languageIndex)
      var startIndex = chunkArray.reduce((a, b) => parseInt(a) + parseInt(b))
      
      var chunkStartIndex = 0;
      if(index === 0){
        chunkStartIndex = 0;
      }
      else{
        chunkStartIndex =  startIndex
      }

      console.log(chunkStartIndex, "chunkStartIndex")
      console.log(noOfquestions, "noOfquestions" )
 
      var cloneinterviewDetails = interviewDetails.slice(0);      
      var chunkConceptQuestionAnswers = cloneinterviewDetails.splice(chunkStartIndex , noOfquestions);

      console.log("chunkConceptQuestionAnswers");
      console.log(chunkConceptQuestionAnswers)

      var totalScore = 0
      chunkConceptQuestionAnswers.map((a, index) => {
        console.log("score",a.score)
        totalScore += parseInt(a.score)   
      })
      console.log("totalScore");
      console.log(totalScore);

      // Coding Score
      var cloneArrnumberOfCodingQuestions = reportDetails.Test.numberOfCodingQuestions.slice(0);
      var cloneArrnumberOfConceptQuestions = reportDetails.Test.numberOfQuestions.slice(0);
      var totalCodingScore = 0

      if(cloneArrnumberOfCodingQuestions && cloneArrnumberOfCodingQuestions.length > 0){
        var numberOfCodingQuestions = cloneArrnumberOfCodingQuestions[index];

        var chunkCodingArray = []
        var previousCodingQuestions = 0
        
        if(index !== 0){
          chunkCodingArray = cloneArrnumberOfCodingQuestions.splice(0, languageIndex)
          previousCodingQuestions = chunkCodingArray.reduce((a, b) => parseInt(a) + parseInt(b))
        }
        var totalpreviousNoOfQuestions = 0

        if(cloneArrnumberOfConceptQuestions.length > 0){
          totalpreviousNoOfQuestions = cloneArrnumberOfConceptQuestions.reduce((a, b) => parseInt(a) + parseInt(b));
        }

        var codestartIndex = 0;
        if(index === 0){
          codestartIndex = totalpreviousNoOfQuestions;
        }
        else{
          codestartIndex =  (totalpreviousNoOfQuestions + previousCodingQuestions )
        }
      
        console.log(codestartIndex, "codestartIndex")
        console.log(numberOfCodingQuestions, "numberOfCodingQuestions" )


        let questionsAnswers = interviewDetails.slice(0);       

        var chunkQuestionAnswers = questionsAnswers.splice(codestartIndex , numberOfCodingQuestions);

        console.log("chunkQuestionAnswers");
        console.log(chunkQuestionAnswers)

        chunkQuestionAnswers.map((b, index) => {
          console.log("coding score",b.score)
          totalCodingScore += parseInt(b.score)   
        })
        console.log("totalCodingScore");
        console.log(totalCodingScore);
      }
      return totalScore + totalCodingScore ;
    }
  }

  const getTotalQuestions = (index, reportDetails) => {
    var totalNoOfQuestions = 0;
    if(reportDetails && reportDetails.Test){
      var conceptQuestions = reportDetails.Test.numberOfQuestions[index];
      var codingQuestionsArr = reportDetails.Test.numberOfCodingQuestions;
      var codingQuestions = 0;
      if(codingQuestionsArr && codingQuestionsArr.length > 0){
        codingQuestions = reportDetails.Test.numberOfCodingQuestions[index];
      }
      totalNoOfQuestions = conceptQuestions + codingQuestions;
    }
    return totalNoOfQuestions;
  }

 

  const getSectionAnswered = (index, interviewDetails, reportDetails, wrongAnswersFlag) => {
    if(reportDetails && reportDetails.Test){

      let cloneArrnumberOfQuestions = reportDetails.Test.numberOfQuestions.slice(0);

      var noOfquestions = cloneArrnumberOfQuestions[index];
      var languageIndex = 0

      if(index === 0 || index < (cloneArrnumberOfQuestions.length - 1)) {
        languageIndex =  index + 1
      }
      else
      {
        languageIndex =  index
      }

      var chunkArray = cloneArrnumberOfQuestions.splice(0, languageIndex)
      var startIndex = chunkArray.reduce((a, b) => parseInt(a) + parseInt(b))
      
      var chunkStartIndex = 0;
      if(index === 0){
        chunkStartIndex = 0;
      }
      else{
        chunkStartIndex =  startIndex
      }

      console.log(chunkStartIndex, "chunkStartIndex")
      console.log(noOfquestions, "noOfquestions" )
 
      var cloneinterviewDetails = interviewDetails.slice(0);      
      var chunkConceptQuestionAnswers = cloneinterviewDetails.splice(chunkStartIndex , noOfquestions);

      console.log("chunkConceptQuestionAnswers");
      console.log(chunkConceptQuestionAnswers)

      var totalAnswered = 0
      chunkConceptQuestionAnswers.map((a, index) => {
        console.log("score",a.score)
        if(wrongAnswersFlag){
          if(parseInt(a.score) === 0){
            totalAnswered += 1
          }
        }
        else{
          if(parseInt(a.score) !== 0){
            totalAnswered += 1
          }
        }    
      })
      console.log("totalAnswered");
      console.log(totalAnswered);

      // Coding Score
      var cloneArrnumberOfCodingQuestions = reportDetails.Test.numberOfCodingQuestions.slice(0);
      var cloneArrnumberOfConceptQuestions = reportDetails.Test.numberOfQuestions.slice(0);
      var totalCodingAnswered = 0

      if(cloneArrnumberOfCodingQuestions && cloneArrnumberOfCodingQuestions.length > 0 ){

        var numberOfCodingQuestions = cloneArrnumberOfCodingQuestions[index];
        var chunkCodingArray = []
        var previousCodingQuestions = 0
        
        if(index !== 0){
          chunkCodingArray = cloneArrnumberOfCodingQuestions.splice(0, languageIndex)
          previousCodingQuestions = chunkCodingArray.reduce((a, b) => parseInt(a) + parseInt(b))
        }
        var totalpreviousNoOfQuestions = 0

        if(cloneArrnumberOfConceptQuestions.length > 0){
          totalpreviousNoOfQuestions = cloneArrnumberOfConceptQuestions.reduce((a, b) => parseInt(a) + parseInt(b));
        }

        var codestartIndex = 0;
        if(index === 0){
          codestartIndex = totalpreviousNoOfQuestions;
        }
        else{
          codestartIndex =  (totalpreviousNoOfQuestions + previousCodingQuestions )
        }
      
        console.log(codestartIndex, "codestartIndex")
        console.log(numberOfCodingQuestions, "numberOfCodingQuestions" )


        let questionsAnswers = interviewDetails.slice(0);       

        var chunkQuestionAnswers = questionsAnswers.splice(codestartIndex , numberOfCodingQuestions);

        console.log("chunkQuestionAnswers");
        console.log(chunkQuestionAnswers)

        chunkQuestionAnswers.map((b, index) => {
          console.log("coding score",b.score)
          if(wrongAnswersFlag){
            if(parseInt(b.score) === 0){
              totalCodingAnswered += 1
            }
          }
          else{
            if(parseInt(b.score) !== 0){
              totalCodingAnswered += 1
            }
          }            
        })
        console.log("totalCodingAnswered");
        console.log(totalCodingAnswered);
      }
      return totalAnswered + totalCodingAnswered ;
    }
  }

  const getOverallTimeTaken = (reportData) => {
    if(reportData && reportData.Interviews){
      var start = new Date (reportData.Interviews[0].createdAt)
      var end = new Date (reportData.Results[0].createdAt)
      var timestamp = (end - start) 
      timestamp = timestamp - (end.getTimezoneOffset() - start.getTimezoneOffset())
      var milliseconds = Math.floor((timestamp % 1000) / 100),
      seconds = Math.floor((timestamp / 1000) % 60),
      minutes = Math.floor((timestamp / (1000 * 60)) % 60),
      hours = Math.floor((timestamp / (1000 * 60 * 60)) % 24);

      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;
      var timeTaken = "";
      if(hours > 0){
        timeTaken = hours + " hours " + minutes + " minutes "  + seconds + " seconds " ;
      }
      else{
        timeTaken = minutes + " minutes "  + seconds + " seconds ";
      }
      return timeTaken
    }
  }

  const millisecondsToMinutes = (ms) => {
    var seconds = Math.floor((ms / 1000) % 60),
    minutes = Math.floor((ms / (1000 * 60)) % 60),
    hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    var timeTaken = "";
    if(hours > 0){
      timeTaken = hours + " hours " + minutes + " minutes "  + seconds + " seconds " ;
    }
    else{
      timeTaken = minutes + " minutes "  + seconds + " seconds ";
    }
    return timeTaken
  }

  const getSectionPercentage = (index, interviewDetails, reportDetails) => {   
    if(reportDetails && reportDetails.Test){      
      var totalSoreObtained = getSectionScore(index, interviewDetails, reportDetails)
      var totalQuestions = getTotalQuestions(index, reportDetails)
      var totalSore = totalQuestions * 10;
      var percentageOfScore = (totalSoreObtained * 100 ) / totalSore;
      return percentageOfScore.toFixed(2);
    }
  }

  return (
    <>
    { !loading &&  
    <div className='pdf-report px-0'>
      <div >
        <Typography>
          <h4 className='pdf-header'>Assessment Profile</h4>
        </Typography>
        <hr/>
      </div >      
      <div >
        <Typography className='pdf-typography'>
          <span>Candidate ID :</span> <b>{reportData.email}</b>
        </Typography>
        <Typography className='pdf-typography'>
        <span>Candidate Name : </span> <b> {reportData.firstName + " " + reportData.lastName }</b>
        </Typography>
        <Typography className='pdf-typography'>
        <span>Date : </span> <b> {getDateFormat(reportData.createdAt)}</b>
        </Typography>
        <Typography className='pdf-typography'>
        <span>Assessment Name : </span> <b>{reportData.Test && reportData.Test.programmingLanguage.join(", ")}</b>
        </Typography>        
        <Typography className='pdf-typography'>
          <span>Coding Questions Included : </span> 
          <b>{reportData.Test && reportData.Test.numberOfCodingQuestions && reportData.Test.numberOfCodingQuestions.length > 0 ? "YES" : "NO"} </b>
        </Typography>      
        <Typography  className='pdf-typography'>
        <span>Client Name : </span> <b>Mobile Programming</b>
        </Typography>
      </div >
      <br/>
      <div>
        <h5 className='pdf-sub-header'>Score Analysis</h5>      
      </div >
      <hr/>
      <div>
        <ul className="px-0">
          <li className='report-list'>
            <div class="Row">
              <div className='Column'>Average Score :  <b> {reportData.Results && reportData.Results[0].result} / 10</b></div>  
              <div className='Column'>Total Score :  <b> {getTotalScore(interviewDetailsData)} / {reportData.Test && reportData.Test.numberOfSelectedQuestions * 10}</b></div>  
              <div className='Column'>Overall Time Taken : <b> {getOverallTimeTaken(reportData)}</b></div>  
              
            </div>
          </li>
        </ul>
      </div>
      <div class="Row data_row">      
        <div  class="Column" >
          <Typography className="mb-3">
          { (reportData.Test && reportData.Results ) ? getGrade(interviewDetailsData, reportData) : ""} &nbsp; ({ (reportData.Test && reportData.Results ) ? getScorePercentage(interviewDetailsData, reportData) : 0}%)
          </Typography>
          <ReactSpeedometer
            forceRender={true}
            width={300}
            height={300}
            minValue={0}
            maxValue={100}
            value = { (reportData.Test && reportData.Results ) ? getScorePercentage(interviewDetailsData, reportData) : 0}
            customSegmentStops={[0, 25, 50, 75, 100]}
            segmentColors={["firebrick", "tomato", "gold", "limegreen"]}
            needleColor="steelblue"
            needleTransitionDuration={10}
            needleTransition="easeElastic"
          />
        </div>
        <div class="Column side-list"  >
          <ul className="px-0">
            <li className='report-list'>
              <div class="Row">
                <div className='chart-details-first chart-details-size Column'></div>  
                <div className='Column report-list-div'> BEGINNER (0% - 25%)</div>  
              </div>
            </li>

            <li className='report-list'>
              <div class="Row">
                <div className='chart-details-second chart-details-size Column'></div>  
                <div className='Column report-list-div'>  INTERMEDIATE (26% - 50%)</div>  
              </div>
            </li>

            <li className='report-list'>
              <div class="Row">
                <div className='chart-details-third chart-details-size Column'></div>  
                <div className='Column report-list-div'>EXPERIENCED (51% - 75%)</div>  
              </div>
            </li>

            <li className='report-list'>
              <div class="Row">
                <div className='chart-details-four chart-details-size Column'></div>  
                <div className='Column report-list-div'> PROFICIENT (76% - 100%)</div>  
              </div>
            </li>
          </ul> 
        </div>    
      </div>
      <div class="Row section-percentage"> 
        <h6 className='pdf-header'>Section Percentage</h6>
        <ul className="px-0">  
        {reportData && reportData.Test && reportData.Test.programmingLanguage.map((item, index) => (               
            <li className='report-list'>
            <div class="Row">
              <div className='Column'>{ item.toLowerCase() }</div>  
              <div className='Column'></div> 
              <div className='Column report-list-div'> 
                <div className="percent_bar">
                  <Line percent = {(reportData && interviewDetailsData) ?
                                 getSectionPercentage(index, interviewDetailsData, reportData ) : 0} 
                                 strokeWidth={5} 
                                trailColor = "#e7e3e3"
                                trokeColor="#74ba74" /> 
                  <span> ( {getSectionScore(index, interviewDetailsData, reportData )}/{ getTotalQuestions(index, reportData) * 10} )  
                    <b> &nbsp;{ (reportData && interviewDetailsData) ? getSectionPercentage(index, interviewDetailsData, reportData ) : 0}% </b>
                  </span>
                </div>
              </div>              
            </div>
            </li>
           ))
          }

        {reportData && reportData.Test && getAdminQuestionsNos(reportData) > 0 &&             
            <li className='report-list'>
            <div class="Row">
              <div className='Column'>Admin Questions</div>  
              <div className='Column'></div> 
              <div className='Column report-list-div'> 
              <div className="percent_bar">
                <Line percent = {(reportData && interviewDetailsData) ? 
                              getAdminQuestionsPercentage(interviewDetailsData, reportData ) : 0} 
                              strokeWidth={5} 
                              trailColor = "#e7e3e3" 
                              strokeColor="#2DB7F5" /> 
                  <span> ( {getAdminQuestionsScore(interviewDetailsData, reportData )}/{getAdminQuestionsNos(reportData) * 10} ) 
                    <b>&nbsp;{(reportData && interviewDetailsData) ? getAdminQuestionsPercentage(interviewDetailsData, reportData ) : 0}% </b>
                  </span>
                </div>
              </div>              
            </div>
            </li>
          }

        </ul>
      </div >

      <div> 
        <h5 className='pdf-sub-header'>Skill Analysis</h5>
        <hr/>
        {reportData && reportData.Test && reportData.Test.programmingLanguage.map((item, index) => (
          <>           
            <h6 className="testNameHeading"><b>{item.toLowerCase()}</b></h6>
            <ul className="px-0"> 
              <li className='report-list'>
              <div class="Row">
                <div className='Column'>Total Score : <b>({getSectionScore(index, interviewDetailsData, reportData )}/{ getTotalQuestions(index, reportData) * 10})</b> </div>  
                <div className="Column"></div>
                <div className='Column'> Time Taken : <b> { millisecondsToMinutes(getSectionTimeTaken(index, interviewDetailsData, reportData ))}</b></div> 
              </div>
              </li>            
          </ul>

          <b className="testNameHeading"> Question Analysis : </b>
          <ul className="px-0">
         
          <li className='report-list'>
            <div class="Row">
              <div className='Column'> Total Question :<b>  {getTotalQuestions(index, reportData)}</b></div>  
              <div className='Column'> Correct :  <b>  {getSectionAnswered(index, interviewDetailsData, reportData, false)} </b></div> 
              <div className='Column'> Wrong / Skipped : <b> {getSectionAnswered(index, interviewDetailsData, reportData, true )}</b></div> 
            </div>
          </li>
        </ul>
        {index < reportData.Test.programmingLanguage.length-1 &&
          <hr/>
        }
        </>
        ))
      }

      {reportData && reportData.Test && getAdminQuestionsNos(reportData) > 0 &&          
          <>
            <hr/>    

            <h6 className="testNameHeading"><b>Admin Questions</b></h6>
            <ul className="px-0"> 
              <li className='report-list'>
              <div class="Row">
                <div className='Column'>Total Score : <b>({getAdminQuestionsScore(interviewDetailsData, reportData )}/{getAdminQuestionsNos(reportData) * 10})</b> </div>  
                <div className="Column"></div>
                <div className='Column'> Time Taken : <b>   { millisecondsToMinutes(getAdminQuestionsTimeTaken(interviewDetailsData, reportData ))}</b></div> 
              </div>
              </li>            
          </ul>

          <b className="testNameHeading"> Question Analysis : </b>
          <ul className="px-0">
         
          <li className='report-list'>
            <div class="Row">
              <div className='Column'> Total Question :<b>  {getAdminQuestionsNos(reportData)}</b></div>  
              <div className='Column'> Correct :  <b>  {getAdminAnswered(interviewDetailsData, reportData, false)} </b></div> 
              <div className='Column'> Wrong / Skipped : <b> {getAdminAnswered(interviewDetailsData, reportData, true )}</b></div> 
            </div>
          </li>
        </ul>
          </>
        }

      </div >
      
      <div className="test-log"> 
        <h5 className='pdf-sub-header'>Test Log</h5>
        <hr/>       
        <ul className="px-0">
          <li className='report-list'>
            <div class="Row">
              <div> Appeared on :<b> {reportData && reportData.Interviews ? getDateFormat(reportData.Interviews[0].createdAt) : ""}</b></div>  
              <div > Completed on : <b> {reportData && reportData.Results ? getDateFormat(reportData.Results[0].createdAt) : ""}</b></div> 
              <div > Report Generated on : <b> {getDateFormat(new Date())}</b></div> 
            </div>
          </li>
        </ul>
      </div>
    </div>
    }
    </>
  );
}

export default ReportDocument;
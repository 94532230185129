import { Box, Container, Grid, Typography } from "@mui/material";
import { DashboardLayout } from "../components/dashboard-layout";
import { Profile } from "../components/user-profile/Profile";
import { ProfileDetails } from "../components/user-profile/ProfileDetails";

const UserProfile = () => {
  return (
    <>
      <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Container maxWidth={false}>
            <Typography
              sx={{ mb: 3 }}
              variant="h4"
              className="profile-page-title"
            >
              Profile
            </Typography>
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                <Profile />
              </Grid>
              <Grid item lg={8} md={6} xs={12}>
                <ProfileDetails />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </DashboardLayout>
    </>
  );
};

export default UserProfile;

import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Drawer, useMediaQuery } from "@mui/material";
import Logo from "../assets/images/logo.png";
import { NavItem } from "./nav-item";
import ListIcon from "@mui/icons-material/List";
import { useNavigate } from "react-router-dom";
import { setIsAuthenticated } from '../actions/login';
import { useDispatch, useSelector } from 'react-redux';

const adminItems = [
  {
    href: "/tests",
    icon: <ListIcon fontSize="small"/>,
    title: "Test Management",
  },
  {
    href: "/candidates",
    icon: <ListIcon fontSize="small"/>,
    title: "Candidate Management",
  },
  
];

export const DashboardSidebar = (props) => {
  const { open, onClose } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lgUp = useMediaQuery(() => "1200", {
    defaultMatches: true,
    noSsr: false,
  });

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: "white",
          backgroundBlendMode: "multiply",
          color: "red",
        }}
        
      >
        <div>
          <Box sx={{ m: 2, ml: 3 }}>
            <img src={Logo} height="80px" alt="Open AI logo" className="buttonTheme" />
          </Box>
        </div>
        <Box sx={{ flexGrow: 1, color: "white" }}>
          {adminItems.map((item) => (
            
            <NavItem
              key={item.title}
              href={item.href}
              onClick={() => {
                navigate(item.href);
                // eslint-disable-next-line no-unused-expressions
               
              }}
              icon={item.icon}
              title={item.title}
              className="sidebar_items"
              subPath={item.subPath}
            />
          ))}
        </Box>
        <Divider sx={{ borderColor: "#2D3748" }} />
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="right"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            color: "#f5f5f5",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Container, TextField, Box, ListItem, List } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import axios from "axios";
import * as Constants from "../../constants";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from "@mui/material/CircularProgress"
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import EasyEdit, { Types } from 'react-easy-edit';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .LeftSection" : {
    float : "left"
  },
  "& .RightSection" : {
    float : "right"
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const AddTest = (props) => {
  const [open, setOpen] = useState(false);
  const [noOfQuestions, setNoOfQuestions] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [AIQuestions, setAIQuestions] = useState([]);
  const [CodingQuestions, setAICodingQuestions] = useState([]);  
  const [checkboxGroupShow, setCheckboxGroupShow] = useState(false);  
  const [selectedQuestionsData, setSelectedQuestionsData] = useState([]);
  const [selectedCodingQuestionsData, setSelectedCodingQuestionsData] = useState([]);  
  const [extraQuestionsData, setExtraQuestionsData] = useState([]);  
  const [currentExtraQuestions, setCurrentExtraQuestionsData] = useState("");
  const [codingQuestionsFlag, setCodingQuestionsFlag] = useState(false);  
  const [technologyName, setTechnologyName] = useState("")
  var [aiQuestionCurrentValue, setAiQuestionCurrentValue] = useState([[]])
  var [codingQuestionCurrentValue, setCodingQuestionCurrentValue] = useState([[]])
  var [selectedNoOfConceptQuestionsArray, setSelectedNoOfConceptQuestionsArray] = useState([])
  var [selectedNoOfCodingQuestionsArray, setSelectedNoOfCodingQuestionsArray] = useState([])
  var [CodingQuestionsPercentage, setCodingQuestionsPercentage] = useState(0)
  var [assessmentName, setAssessmentName] = useState("")
  var [testPrompt, setTestPrompt] = useState("")
  var [experience, setExperience] = useState("")  

  const handleClose = () => {
    setOpen(false);
    clearAllValues();
  };
  const handleChange = (value) => {
    if(value.split(",").length > 0 ){
      value = value.toUpperCase();
      setTechnologyName(value.split(","));
    }
  };
  
  const getOverallSelectedNoofquestions = () => {
    return (extraQuestionsData.length > 0 ? extraQuestionsData.length : getExtraquestionData(currentExtraQuestions).length) + selectedQuestionsData.length + selectedCodingQuestionsData.length;
  }

  const getOverallNoofquestions = () => {
    var overallNos = 0;
  
    if(CodingQuestionsPercentage !== 0 && CodingQuestionsPercentage !== "0" ){
      var codingquestionNos = getPercentageOfCodingQuestionNos()
      var totalCodingquestionNos = codingquestionNos.reduce((a, b) => parseInt(a) + parseInt(b))
      overallNos = parseInt(noOfQuestions) - parseInt(totalCodingquestionNos);
    }
    else{
      overallNos = noOfQuestions;
    }
  
    return overallNos;
  }

  const getCalculatedNoofQuestions = () => {
    // var averageNos = parseInt(noOfQuestions / technologyName.length);
    console.log( noOfQuestions)
    var codingquestionNos = ( noOfQuestions * CodingQuestionsPercentage)  / 100  ;

    codingquestionNos = codingquestionNos / technologyName.length;

    console.log("single codingquestionNos", codingquestionNos);
    
    if(codingquestionNos < 1  ) codingquestionNos = 1;
    return codingquestionNos;
  }
 

  const getPercentageOfCodingQuestionNos = () => {
    var codingQuestionNos = [];
    if(CodingQuestionsPercentage !== 0 && CodingQuestionsPercentage !== "0" ){
      var codingquestionNo = getCalculatedNoofQuestions()
      codingQuestionNos = Array(technologyName.length).fill(Math.round(codingquestionNo))
    }    
    return codingQuestionNos;
  }
  
  const splitNoOfquestions = (noOfQuestions, testNameCount) => {
    var numberOfQuestionsArray = []
    if(noOfQuestions < testNameCount)
    {      
      return Array(testNameCount).fill(0)// NoOfquestion less than the test name length
    }
    else if (noOfQuestions % testNameCount === 0)
    {
      for(let i = 0; i < testNameCount; i++)
        return Array(testNameCount).fill((noOfQuestions / testNameCount))
    }
    else
    {
      let zp = testNameCount - (noOfQuestions % testNameCount);
      let pp = Math.floor(noOfQuestions / testNameCount);
      for(let i = 0; i < testNameCount; i++)
      {    
        if(i >= zp)
          numberOfQuestionsArray.push((pp + 1))
        else
          numberOfQuestionsArray.push(pp)
      }
    }

    return numberOfQuestionsArray;
  }

  const getAllNoOfQuestionNos = () => {      
    var numberOfQuestions = splitNoOfquestions(parseInt(getOverallNoofquestions()), technologyName.length);
    if(numberOfQuestions[0] > 0)
      return numberOfQuestions;
    else
      return false;
  }

  const getNoOfQuestions = () => {
    var numberOfOverallQuestions = 0;
    numberOfOverallQuestions = getOverallSelectedNoofquestions();
    return parseInt(numberOfOverallQuestions);
  }

  useEffect(() => {
    setCheckboxGroupShow(true);
    setAIQuestions(aiQuestionCurrentValue);
  }, [aiQuestionCurrentValue]);

  const getExtraquestionData = (question) => {
    var temp = [];
    if(question !== "")
      temp.push(question)
    return temp
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");

    if(requiredValidation()){
      var noOfquestions = getAllNoOfQuestionNos();
      
      if(!noOfquestions){
        var totalCodingquestionNos = 0;
        if(CodingQuestionsPercentage !== 0 && CodingQuestionsPercentage !== "0" ){
          var codingquestionNos = getPercentageOfCodingQuestionNos()
          totalCodingquestionNos = codingquestionNos.reduce((a, b) => parseInt(a) + parseInt(b))  
        }
        var minimumNoOfQuestions = totalCodingquestionNos + technologyName.length;
        setError("Please enter the number of questions as " + minimumNoOfQuestions + " or more than " + minimumNoOfQuestions + ". So that every section will have atleast 1 question.");      
      }
      else if( selectedQuestionsData.length === 0 && selectedCodingQuestionsData.length === 0 && currentExtraQuestions === "" && extraQuestionsData.length === 0 ){
        setError("Please select or add the questions for the test.");
      }
      else{
        var data = {
          "numberOfQuestions": selectedNoOfConceptQuestionsArray,
          "noOfCodingQuestions" : selectedNoOfCodingQuestionsArray,
          "programmingLanguage": technologyName,
          "durationInMinutes": 90,
          "testType" : "",
          "extraQuestionsData": (extraQuestionsData.length ? extraQuestionsData : getExtraquestionData(currentExtraQuestions)),
          "selectedQuestionsData": selectedQuestionsData,
          "selectedCodingQuestions": selectedCodingQuestionsData,
          "numberOfSelectedQuestions" : getNoOfQuestions(),
          "assessmentName" : assessmentName,
          "prompt" : testPrompt
        }

        console.log(data);
        var JwtToken = localStorage.getItem("JwtToken");  
        setLoading2(true);
        axios
          .post( 
            Constants.APIURL + "test" , data ,  {
              headers: {
                'Authorization': `${JwtToken}`
              }})
        .then((response) => {
          setLoading2(false);
          if(response.status === 422){
            setError(response.data.message);
          }
          else{        
            setOpen(false);
            setNoOfQuestions(0);
            setAIQuestions([]);
            setAICodingQuestions([]);
            setSelectedQuestionsData([]);
            setSelectedCodingQuestionsData([]);
            setExtraQuestionsData([]);
            setCurrentExtraQuestionsData("");
            window.location.reload();      
          }
        }).catch((err)=>
        setError(err));
      }
    }
  }
  
  const getAIQuestions = () => {
    setAIQuestions([]);
    setAICodingQuestions([]);
    setSelectedQuestionsData([]);
    setSelectedCodingQuestionsData([]);
    
    if(requiredValidation()){
      var noOfquestions = getAllNoOfQuestionNos();
      if(!noOfquestions){
        var totalCodingquestionNos = 0;
        if(CodingQuestionsPercentage !== 0 && CodingQuestionsPercentage !== "0" ){
          var codingquestionNos = getPercentageOfCodingQuestionNos()
          totalCodingquestionNos = codingquestionNos.reduce((a, b) => parseInt(a) + parseInt(b))  
        }
        var minimumNoOfQuestions = totalCodingquestionNos + technologyName.length;        
        setError("Please enter the number of questions as " + minimumNoOfQuestions + " or more than " + minimumNoOfQuestions + ". So that every section will have atleast 1 question.");
      }
      else{
        console.log("noOfquestions");
        console.log(noOfquestions);
        setSelectedNoOfConceptQuestionsArray(noOfquestions);
        setSelectedNoOfCodingQuestionsArray((CodingQuestionsPercentage !== 0 && CodingQuestionsPercentage !== "0" ) ? getPercentageOfCodingQuestionNos() : [])
        
        var data = {
          "prompt": (experience !== "" )? " The questions should be for a " + experience + " years of experienced candidate . " + testPrompt : testPrompt,
          "languages": technologyName, 
          "numberOfQuestions": noOfquestions, 
          "codingLanguages" : (CodingQuestionsPercentage !== 0 && CodingQuestionsPercentage !== "0" ) ? technologyName : [],
          "numberOfCodingQuestions" : (CodingQuestionsPercentage !== 0 && CodingQuestionsPercentage !== "0" ) ? getPercentageOfCodingQuestionNos() : [],
        }
        console.log(data);
        setLoading(true);
        axios
          .post(
            Constants.APIURL + "multiLanguageQuestions", data)
        .then((response) => {
          setLoading(false);
          if(response.data.questions.length >= 1) {

          setAIQuestions(response.data.questions)
          setAICodingQuestions(response.data.codingQuestions)
          setSelectedQuestionsData(response.data.questions.flat(1));
          setSelectedCodingQuestionsData(response.data.codingQuestions.flat(1));

          }
        });
      }
    }
  }

  const setSelectedQuestions = (languageIndex, questionIndex) => {
    setError("");
    let temp = selectedQuestionsData;
    if(selectedQuestionsData && selectedQuestionsData.length && selectedQuestionsData.includes(AIQuestions[languageIndex][questionIndex])){
      var index = selectedQuestionsData.indexOf(AIQuestions[languageIndex][questionIndex]);
      selectedQuestionsData.splice(index, 1);
      setSelectedQuestionsData(selectedQuestionsData);

      var tempSelectedNoOfConceptQuestionsArray = selectedNoOfConceptQuestionsArray;
      var tempConceptQuestions = selectedNoOfConceptQuestionsArray[languageIndex];
      tempSelectedNoOfConceptQuestionsArray[languageIndex] = tempConceptQuestions - 1;
      setSelectedNoOfConceptQuestionsArray(tempSelectedNoOfConceptQuestionsArray)

    }
    else{
      temp.push(AIQuestions[languageIndex][questionIndex]);
      setSelectedQuestionsData(temp);

      var tempSelectedNoOfConceptQuestionsArray = selectedNoOfConceptQuestionsArray;
      var tempConceptQuestions = selectedNoOfConceptQuestionsArray[languageIndex];
      tempSelectedNoOfConceptQuestionsArray[languageIndex] = tempConceptQuestions + 1;
      setSelectedNoOfConceptQuestionsArray(tempSelectedNoOfConceptQuestionsArray)
    }

    console.log("selectedNoOfConceptQuestionsArray");
    console.log(selectedNoOfConceptQuestionsArray)
  }

  const setSelectedCodingQuestions = (languageIndex, questionIndex) => {
    setError("");
    let temp = selectedCodingQuestionsData;
    if(selectedCodingQuestionsData && selectedCodingQuestionsData.length && selectedCodingQuestionsData.includes(CodingQuestions[languageIndex][questionIndex])){
      var index = selectedCodingQuestionsData.indexOf(CodingQuestions[languageIndex][questionIndex]);
      selectedCodingQuestionsData.splice(index, 1);
      setSelectedCodingQuestionsData(selectedCodingQuestionsData);
      
      var tempSelectedNoOfCodingQuestionsArray = selectedNoOfCodingQuestionsArray;
      var tempCodingQuestions = selectedNoOfCodingQuestionsArray[languageIndex];
      tempSelectedNoOfCodingQuestionsArray[languageIndex] = tempCodingQuestions - 1;
      setSelectedNoOfCodingQuestionsArray(tempSelectedNoOfCodingQuestionsArray)

    }
    else{
      temp.push(CodingQuestions[languageIndex][questionIndex]);
      setSelectedCodingQuestionsData(temp);
      
      var tempSelectedNoOfCodingQuestionsArray = selectedNoOfCodingQuestionsArray;
      var tempCodingQuestions = selectedNoOfCodingQuestionsArray[languageIndex];
      tempSelectedNoOfCodingQuestionsArray[languageIndex] = tempCodingQuestions + 1;
      setSelectedNoOfCodingQuestionsArray(tempSelectedNoOfCodingQuestionsArray)
    }
    console.log("selectedNoOfCodingQuestionsArray");
    console.log(selectedNoOfCodingQuestionsArray)
  }
  
  const setCurrentExtraQuestions = (value) => {
    setError("");
    setCurrentExtraQuestionsData(value);
  }

  const requiredValidation = () => {
    if(technologyName.length === 0 ){
      setError("Please add the test name.");
      return false;
    }   
    else if(noOfQuestions === "" || parseInt(noOfQuestions) === 0 ){
      setError("Please add the No of questions");
      return false;
    }   
    else{
      return true;
    }    
  }
  
  const numberValidation = (value) => {
    var reg = /^\d+$/
    setError("");
    if(value.match(reg)){
      if(parseInt(value) > 50){
        setError("50 is the maximum number of questions.");
        return false;
      }
      else{
        setNoOfQuestions(value)
        return true
      }
    }
    else if (value === ""){
      setNoOfQuestions(value)
      return true
    }
    else {
      setError("Please enter the valid number.");
      return false
    }

  }
  
  const setNoOfquestion = (value) => {
    numberValidation(value)
  }
  
  const handleAddExtraQuestion = () => {
    if(currentExtraQuestions !== ""){
      if(extraQuestionsData.length > 0){
        let temp = extraQuestionsData;
        temp.push(currentExtraQuestions);
        setExtraQuestionsData(temp);
      }
      else {
        let temp = [];
        temp.push(currentExtraQuestions);
        setExtraQuestionsData(temp);
      }
      setCurrentExtraQuestionsData("");
    }
    else{
      setError("Please enter your question to add.");
    }  
  }

  const handleCloseExtraQuestion = (codeIndex) => {
    extraQuestionsData.splice(codeIndex, 1);
    setExtraQuestionsData([...extraQuestionsData ]);
  }

  const handleSetCodingQuestionsFlag = (e) => {
    setCodingQuestionsFlag(e.target.value)
    setAIQuestions([]);
    setAICodingQuestions([]);
    setSelectedQuestionsData([]);
    setSelectedCodingQuestionsData([]);
    setAiQuestionCurrentValue([]);
    setCodingQuestionCurrentValue([]);
    setError("");
  }
  
  const aiQuestionChange = (e, languageIndex, questionIndex) => {
    if(e !== ""){
      aiQuestionCurrentValue = AIQuestions
      aiQuestionCurrentValue[languageIndex][questionIndex] = e
    }
    else{
      aiQuestionCurrentValue = AIQuestions
      setAiQuestionCurrentValue(aiQuestionCurrentValue);
      // alert("Please deselect this question to remove the same from question set.")
    }

  }
  const codingQuestionChange = (e, languageIndex, questionIndex) => {
    if(e !== ""){
      codingQuestionCurrentValue = CodingQuestions;
      codingQuestionCurrentValue[languageIndex][questionIndex] = e
    }
    else{
      codingQuestionCurrentValue = CodingQuestions;
      // alert("Please deselect this question to remove the same from question set.")
    }
  }
  const handleUpdateAIQuestion = () => {
    var temp = aiQuestionCurrentValue;
    setAIQuestions(temp)
  };
  const handleUpdateCodingQuestion = () => {
    var temp = codingQuestionCurrentValue;
    setAICodingQuestions(temp)
  };
  
  const cancel = () => {
    console.log("Cancelled.");
  };
  
  const clearAllValues = () => {
    setNoOfQuestions("");
    setAIQuestions([]);
    setAICodingQuestions([]);
    setSelectedQuestionsData([]);
    setSelectedCodingQuestionsData([]);
    setExtraQuestionsData([]);
    setCurrentExtraQuestionsData("");
    setTechnologyName("");
    setAiQuestionCurrentValue([]);
    setCodingQuestionCurrentValue([]);
    setCodingQuestionsFlag(false)
    setCheckboxGroupShow(false);
    setError("");    
  }

  useEffect(() => {
   clearAllValues()
  },[open])

  useEffect(() => {
    setAIQuestions([]);
    setAICodingQuestions([]);
    setSelectedQuestionsData([]);
    setSelectedCodingQuestionsData([]);
    setAiQuestionCurrentValue([]);
    setCodingQuestionCurrentValue([]);
    setError("");
  },[codingQuestionsFlag])

  const handleCodingQuestionsPercentage = (e) => {
    console.log(e.target.value)
    setCodingQuestionsPercentage(e.target.value)
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>      
        <Button
          color="success"
          variant="contained"
          sx={{ px: 5, height: "56px" }}
          className="buttonTheme"
          onClick={() => setOpen(true)}
        >
          Add Test
        </Button>
      </Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        className="test_modal"
      >
        <form onSubmit={handleSubmit} className="w-100">
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            fontSize: "1.5rem",
            fontWeight: "700",
            color: (theme) => theme.palette.primary.main,
            borderBottom: "3px solid #D1D5DB",
          }}
        >
          Add Test
        </BootstrapDialogTitle>
        <DialogContent className="LeftSection" sx={{ width: "45%", my: 2 }}>
          <Container sx={{ my: 2 }}>
            <TextField                
              fullWidth
              type="text"
              onChange={(e) => setAssessmentName(e.target.value.toUpperCase())}
              label="Job Description"
              margin="normal"
              variant="outlined"
              value={assessmentName}
              sx={{ width: "100%", my: 2 }}
              required
            /> 
          </Container>      
          <Container sx={{ my: 2 }}>
            <TextField
              required
              fullWidth
              type="text"            
              value={ technologyName && technologyName.length >0 && technologyName.toString() }
              onChange={(e) => handleChange(e.target.value)}
              label={"Keywords (Comma Separated)"}
              margin="normal"
              variant="outlined"
            />
          </Container>
          <Container sx={{ my: 2 }}>
            <TextField              
              fullWidth
              type="text"            
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
              label={"Experience  (in years)"}
              margin="normal"
              variant="outlined"
            />
          </Container>
          <Container sx={{ my: 2 }}>
            <TextField              
              fullWidth
              type="text"            
              value={testPrompt}
              onChange={(e) => setTestPrompt(e.target.value)}
              label={"Prompt (Specify conditions)"}
              margin="normal"
              variant="outlined"
              multiline
              rows={3}
            />
          </Container>         
        </DialogContent>
        <DialogContent className="RightSection" sx={{ width: "55% !important", my: 3 }}> 
        <Container sx={{ my: 2 }}>
            <TextField
              required
              fullWidth
              type="text"            
              value={noOfQuestions}
              onChange={(e) => setNoOfquestion(e.target.value)}
              label={"No Of Questions (Max 50)"}
              margin="normal"
              variant="outlined"
            />
          </Container>
          <Container sx={{ my: 2 }} className="range_slide">
            <FormControl sx={{ m: 0, width: '100%' }}>
            <FormLabel id="demo-radio-buttons-group-label">Add Practical Questions (%)</FormLabel>
            <Slider
              aria-label="Small steps"
              defaultValue={1}              
              step={10}
              marks
              min={0}
              max={100}
              valueLabelDisplay="auto"
              onChange={(e) => handleCodingQuestionsPercentage(e)}
            />

            </FormControl>
          </Container>

          {checkboxGroupShow && 
            <>
            <Container sx={{ width: "100%", my: 1 }}>
              <FormGroup>      
              {AIQuestions && AIQuestions.length > 0 ?       
                <h5>
                  <b>
                    Interview Questions
                  </b>
                </h5>
                : "" 
                }
                {AIQuestions && AIQuestions.length > 0 && AIQuestions.map((languageQuestions , langIndex) => (                                                        
                    languageQuestions.length > 0 && languageQuestions.map( (item, index) => (
                      <List
                        sx={{
                              listStyleType: 'disc',
                              listStylePosition: 'outside',
                              display: 'flex',
                              flexDirection: 'row',
                              padding: 0
                            }}
                            className="manual_question">
                        <ListItem disablePadding={true} sx = {{ width : '30px'}} key = {index}>                             
                          <Checkbox
                            defaultChecked
                            value = {aiQuestionCurrentValue}
                            onChange={() => setSelectedQuestions(langIndex, index)}
                          />
                        </ListItem>
                        <ListItem sx = {{ width : '100%'}} key = {index}>
                        <EasyEdit
                              type={Types.TEXTAREA}
                              onSave={handleUpdateAIQuestion}
                              onCancel={cancel}
                              saveButtonLabel="Save"
                              cancelButtonLabel="Cancel"
                              value = {(aiQuestionCurrentValue && aiQuestionCurrentValue[langIndex] !== undefined && 
                                        aiQuestionCurrentValue[langIndex][index] !== "" && aiQuestionCurrentValue[langIndex][index] !== undefined) ? 
                                        aiQuestionCurrentValue[langIndex][index] : item }
                              onFocus = {(e) => {aiQuestionChange(e, langIndex, index)}}
                              saveButtonStyle = "easy-edit-button"
                              saveOnBlur = {true}
                            /> 
                        </ListItem>
                    </List>
                    ))
                ))}
              </FormGroup>
            </Container>
      
            <Container sx={{ width: "100%", my: 2 }}>
              <FormGroup>
              { CodingQuestions.length > 0 ? <h5>
                  <b>
                    Coding Questions
                  </b>
                </h5> : "" }
                { CodingQuestions.length > 0 && CodingQuestions.map((codingQuestions , codeIndex) => (                                                        
                    codingQuestions.length > 0 && codingQuestions.map( (codingQuestion, index) => (

                      <List
                        sx={{
                              listStyleType: 'disc',
                              listStylePosition: 'outside',
                              display: 'flex',
                              flexDirection: 'row',
                              padding: 0
                            }}
                            className="manual_question">
                        <ListItem disablePadding={true} sx = {{ width : '30px'}} key = {index}>                             
                          <Checkbox 
                            defaultChecked 
                            value = {codingQuestionCurrentValue}
                            onChange={() => setSelectedCodingQuestions(codeIndex, index)}
                          />
                        </ListItem>
                        <ListItem sx = {{ width : '100%'}} key = {index}>
                        <EasyEdit
                              type={Types.TEXTAREA}
                              onSave={handleUpdateCodingQuestion}
                              onCancel={cancel}
                              saveButtonLabel="Save"
                              cancelButtonLabel="Cancel"
                              value = {(codingQuestionCurrentValue && codingQuestionCurrentValue[codeIndex] !== undefined 
                                        && codingQuestionCurrentValue[codeIndex][index] !== "" && 
                                        codingQuestionCurrentValue[codeIndex][index] !== undefined) ? 
                                          codingQuestionCurrentValue[codeIndex][index] : codingQuestion }
                              onFocus = {(e) => {codingQuestionChange(e, codeIndex, index)}}
                              saveButtonStyle = "easy-edit-button"    
                              saveOnBlur = {true}                
                            /> 
                        </ListItem>
                    </List>           
                    ))
                ))}
              </FormGroup>
            </Container>
            </>
          }
        <Typography style={{ color: "red" }}>{error}</Typography> 
        <Stack spacing={2} direction="row" className="btn_group" sx={{ px: 2 , mb: 3}}>
            <Button
              color="success"
              variant="contained"
              sx={(loading ? { px: 20, cursor : "context-menu !important" } :  { px: 20, cursor : "pointer !important" })}
              className="buttonTheme get_question"
              onClick={getAIQuestions} 
              disabled = { loading ? true : false}
              style={{padding: '10px 30px'}}
            >
              Get Questions
              { loading &&  <CircularProgress style={{'color': '#fff'}}/> }
            </Button>
            <Button
              color="success"
              variant="contained"
              sx={(loading ? { px: 20, cursor : "context-menu !important" } :  { px: 20, cursor : "pointer !important" })}
              className="buttonTheme get_question"            
              disabled = { loading ? true : false}
              type="submit"
              style={{padding: '10px 30px'}}
            >
             Create Test        
             { loading2 &&  <CircularProgress style={{'color': '#fff'}}/> }
            </Button>
          </Stack>
      
        </DialogContent>
        </form>
      </BootstrapDialog>
    </>
  );
};

export default AddTest;

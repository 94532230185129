import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Container, Grid, Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import axios from 'axios';
import * as Constants from "./../../constants";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
// import { setIdentityUser, setLoginMode, setIsAuthenticated } from '../../actions/login';
import { Alert } from "../alert";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const SetNewPassword = (props) => {
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // const identityUser = useSelector(state => state.loginReducer.identityUserData);
  const [alert, setAlert] = useState({ open: false, data: "" });
  const alertClose = () => setAlert({ ...alert, open: false });
  const [openPassword, setOpenPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickOpenPassword = () => {
    setOpenPassword(true);
  };
  const handleClickClosePassword = () => {
    setOpenPassword(false);
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .max(255)
        .required("Old Password is required"),
      newPassword: Yup.string()
        .max(255)
        .required("New Password is required"),
      confirmPassword: Yup.string()
        .max(255)
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      const creds = {
        // email: identityUser.email,
        password: values.oldPassword,
        returnSecureToken: true
      }
      if (values.newPassword !== values.confirmPassword) {
        setPasswordError('Confirm Password does not match.');
      } else {
        let data = {
          "id" : localStorage.getItem("loginId"),
          "password" : values.oldPassword
        }
        axios
        .post(Constants.APIURL + "users/checkPassword/", data)
        .then((response) => {
          if (!response.data.error) {
            let data = {
              "id" : localStorage.getItem("loginId"),
              "password" : values.newPassword
            }
            axios
            .post(Constants.APIURL + "users/resetPassword/", data)
            .then((response) => {
              const data = response.data.response;
              if (response.data.status !== 200) {
                setAlert({ open: true, data: response.data.message });
                setOpenPassword(false);
                window.location.reload();
              }
              
            });
          }
          else{
            setPasswordError(response.data.message);
          }
        });
       
      }
  }
});
  return (
    <>
          <Button
        onClick={handleClickOpenPassword}
        className="profile-btn"
        color="primary"
        fullWidth
        variant="text"
      >
        Change Password
      </Button>
      <Alert open={alert.open} data={alert.data} close={alertClose} />
      <BootstrapDialog
        onClose={handleClickClosePassword}
        aria-labelledby="customized-dialog-title"
        width="600"
        open={openPassword}
        maxWidth="lg"
      >
        <form onSubmit={formik.handleSubmit}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            alignItems="center"
            onClose={handleClickClosePassword}
            sx={{
              fontSize: "1.5rem",
              fontWeight: "700",
              color: (theme) => theme.palette.primary.main,
              borderBottom: "3px solid #D1D5DB",
            }}
          >
            Change Password
          </BootstrapDialogTitle>
          <DialogContent>
            <Container sx={{ width: "600px", my: 2 }}>
              <FormControl
                variant="outlined"
                margin="normal"
                autoComplete="current-password"
                fullWidth
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                required
              >
                <Box
                  sx={{
                    my: 2,
                    px: 5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FormControl variant="outlined" margin="normal" autoComplete="password" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      required
                      error={Boolean(formik.touched.oldPassword && formik.errors.oldPassword)}
                      helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                      label="Old Password"
                      name="oldPassword"
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={formik.values.oldPassword}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    my: 2,
                    px: 5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FormControl variant="outlined" margin="normal" autoComplete="current-password" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      New Password
                    </InputLabel>
                    <OutlinedInput
                      required
                      error={Boolean(
                        formik.touched.newPassword && formik.errors.newPassword
                      )}
                      helperText={formik.touched.newPassword && formik.errors.newPassword}
                      label="New Password"
                      name="newPassword"
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={formik.values.newPassword}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    my: 2,
                    px: 5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FormControl variant="outlined" margin="normal" autoComplete="current-password" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      required
                      error={Boolean(
                        formik.touched.confirmPassword && formik.errors.confirmPassword
                      )}
                      helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                      label="Confirm Password"
                      name="confirmPassword"
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={formik.values.confirmPassword}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
                <Box sx={{
                  my: 2,
                  px: 5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}>
                  <Typography style={{ color: "red" }}>{passwordError}</Typography>
                </Box>
              </FormControl>
            </Container>
          </DialogContent>
          <DialogActions sx={{ borderTop: "3px solid #D1D5DB" }}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                color="success"
                variant="contained"
                type="submit"
                sx={{
                  px: 27,
                  fontSize: "15px",
                  margin: "0.4rem"
                }}
                className="buttonTheme"
              >
                CHANGE PASSWORD
              </Button>
            </Grid>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Box, Button, Container } from "@mui/material";
import TestToolbar from "../components/test/TestToolbar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';
import DeleteTest from "../components/test/DeleteTest";
import AddCandidate from "../components/test/AddCandidate";
import ViewCandidates from "../components/test/ViewCandidates";
import ShareTest from "../components/test/ShareTest";
import ViewCandidate from '@mui/icons-material/ViewList';
import EditTest from "../components/test/EditTest";
import { DashboardLayout } from "../components/dashboard-layout";
import moment from "moment";
import axios from "axios";
import * as Constants from '../constants';
import CircularProgress from "@mui/material/CircularProgress"
import TestDetails from "../components/test/testDetails";
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from "@mui/material/IconButton";

const Tests = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const closeDeletePopup = () => {
    setOpenDeletePopup(false);
  };
  const [pageSize, setPageSize] = useState(10);

  const [openditPopup, setOpenEditPopup] = useState(false);
  const [openAddCandidatePopup, setOpenAddCandidatePopup] = useState(false);  
  const [openViewCandidatesPopup, setOpenViewCandidatesPopup] = useState(false);
  const [openShareTestPopup, setOpenShareTestPopup] = useState(false);
  const [openTestDetailsPopup, setOpenTestDetailsPopup] = useState(false);

  let [testNameValue, setTestNameValue] = useState("");  
  let [sharedWIthEmail, setSharedWIthEmails] = useState("");  
  let [noOfquestionsValue, setNoOfquestionsValue] = useState("");
  
  let [deleteID, setDeleteID] = useState("");
  let [testID, setTestID] = useState("");
  let [editID, setEditID] = useState();

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [tableRows, setTableRows] = useState([]);
  const [selectedViewDetails, setSelectedViewDetails] = useState({
    "codingQuestions" : [],
    "aiQuestions" : [],
    "ownQuestions" : [],
    "programmingLanguage" : [],
    "id" : ""
  });
  
  const deleteTest = React.useCallback(
    (id) => () => {
      setDeleteID(id);
      setOpenDeletePopup(true);
    },
    []
  );

  const closeEditPopup = () => {
    setOpenEditPopup(false);
  };
  const closeAddCandidatePopup = () => {
    setOpenAddCandidatePopup(false);
  };

  const addCandidateToTest = React.useCallback(
    (id, test_name) => () => {
      setTestID(id);      
      setTestNameValue(test_name);
      setOpenAddCandidatePopup(true);
    },
    []
  );

  const closeShareTestPopup = () => {
    setOpenShareTestPopup(false);
  };


  const closeViewCandidatesPopup = () => {
    setOpenViewCandidatesPopup(false);
  };

  const closeTestDetailsPopup = () => {
    setOpenTestDetailsPopup(false);
  };

  const viewCandidates = React.useCallback(
    (id, test_name) => () => {
      setTestID(id);
      setTestNameValue(test_name);
      setOpenViewCandidatesPopup(true);
    },
    []
  );
  
  const shareTest = React.useCallback(
    (id, sharedWIthEmails) => () => {
      console.log("sharedWithEmails");
      console.log(sharedWIthEmails);
      setTestID(id);
      setSharedWIthEmails(sharedWIthEmails);
      setOpenShareTestPopup(true);
    },
    []
  );
  

  const viewTestDetails =  React.useCallback(
  (programmingLanguage, codingQuestions, aiQuestions, ownQuestions, id) => () => {
    var data = {
      "codingQuestions" : codingQuestions,
      "aiQuestions" : aiQuestions,
      "ownQuestions" : ownQuestions,
      "programmingLanguage" : programmingLanguage,
      "id" : id
    }
    var temp = {...selectedViewDetails}
    temp = data;
    setOpenTestDetailsPopup(true);
    setSelectedViewDetails(temp)    
  }
 );
  const editTest = React.useCallback(
    (id, test_name, no_of_questions) => () => {
      setEditID(id);
      setTestNameValue(test_name);
      setNoOfquestionsValue(no_of_questions);      
      setOpenEditPopup(true);
    },
    []
  );

  const columns = [    
    {
      field: "display_id",
      headerName: "S.No",
      maxWidth: 50,
      flex: 1,
    },
    {
      field: "assessmentName",
      headerName: "Job Description",
      minWidth: 200,
      flex:3,
      renderCell: (params) => (
        <>
        <span>{params.row.assessmentName}<IconButton
          aria-label="close"
          sx={{
            top: -10,
            color: "#cd5959",
            marginTop: "17px"
          }}
          onClick = {viewTestDetails(params.row.test_name, params.row.codingQuestions, params.row.aiQuestions, params.row.ownQuestions, params.row.id)}
        >
          <VisibilityIcon fontSize = "small" />
        </IconButton></span>
        
      </>
      )
    },
    {
      field: "test_name",
      headerName: "Keywords",
      minWidth: 300,
      flex:3   
    },
    {
      field: "numberOfSelectedQuestions",
      headerName: "No Of Questions",
      minWidth: 100,
      flex: 2,
    },
    {
      field: "interviewsCount",
      headerName: "Interviews count",
      minWidth: 100,
      flex: 2,
    },
    {
      field: "created_date",
      headerName: "Created Date",
      minWidth: 150,
      flex: 2,
      accessor: "created_date",
      renderCell: (row) => (
        <span>{moment(row.value).format("MM/DD/YYYY")}</span>
      ),
    },
    {
      field: "created_by",
      headerName: "Created By",
      minWidth: 100,
      flex:2,
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 50,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon sx={{ color: "#B7345C" }} />}
          label="Delete"
          sx={{ color: "#B7345C" }}
          onClick={deleteTest(params.id)}
          showInMenu
        />,
        // <GridActionsCellItem
        //   icon={<EditIcon />}
        //   label="Edit"
        //   onClick={editTest(params.id, params.row.test_name, params.row.no_of_questions)}
        //   showInMenu
        // />,
        <GridActionsCellItem
          icon={<AddIcon />}
          label="Add Candidate"
          onClick={addCandidateToTest(params.id, params.row.test_name)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<ViewCandidate />}
          label="View Candidates"
          onClick={viewCandidates(params.row.id, params.row.test_name)}
          showInMenu
        />,
        <GridActionsCellItem
        icon={<ScreenShareOutlinedIcon />}
        label="Share Test"
        onClick={shareTest(params.row.id, params.row.sharedWithEmails)}
        showInMenu
      />
      ],
    },
  ];

  const getTests = () => {
    setLoading(true);
    var JwtToken = localStorage.getItem("JwtToken");
    console.log("JwtToken", JwtToken);
    axios
      .get(
        Constants.APIURL + "test", {
          headers: {
            'Authorization': `${JwtToken}`
          }})
      .then((response) => {
        setLoading(false);
        if(response.data && response.data.length > 0 ){
          console.log("Success")
          var i = 0;
          var tests = response.data.map((item) => {
            if(item.ManualTestQuestion !== null ) {
              console.log("codingQuestions", item.ManualTestQuestion.codingQuestions)
            }
            
            i++;
            return {
              id: item.id,  
              display_id: i,   
              test_name: item.programmingLanguage,
              no_of_questions: item.numberOfQuestions,
              noOfCodingQuestions: item.numberOfCodingQuestions,
              numberOfSelectedQuestions: item.numberOfSelectedQuestions,
              created_date: item.createdAt,
              created_by: item.user.firstName + " " + item.user.lastName,         
              aiQuestions: (item.ManualTestQuestion !== null ) ? item.ManualTestQuestion.aiQuestions : null,
              ownQuestions: (item.ManualTestQuestion !== null ) ? item.ManualTestQuestion.ownQuestions: null,
              codingQuestions: (item.ManualTestQuestion !== null ) ? item.ManualTestQuestion.codingQuestions : null,
              sharedWithEmails: item.sharedWithEmails,
              assessmentName : item.assessmentName,
              interviewsCount: (item.Interviews && item.Interviews.length > 0 ) ? item.Interviews.length : 0
            };
          });

          setTableRows(tests);
          setLoading(false);

          if (tests.length > 10) {
            setRowsPerPage([10, 25, 50, 100]);
          }
        }
        else{
          console.log("Failed")
          localStorage.clear()        
        }
      }, error => {
        if (error.response.status === 401) {
          console.log(error.response.message)
          localStorage.clear()         
        }
        return error;
      });
  }

  const search = (value) => {
    setSearchValue(value);
    if (value !== "" && value !== "undefined") {
      let filterRow = [];
      filterRow = tableRows.filter((item) => {
        return (
          item.no_of_questions.includes(searchValue) ||
          item.name.toLowerCase().includes(searchValue)
        );
      });
      setTableRows(filterRow);
      if (filterRow.length > 10) {
        setRowsPerPage([10, 25, 50, 100]);
      }
    }
    else {
      setTableRows(tableRows);
    }
  }

  // useEffect(() => {
  //   if (tableRows.length < 1) {
  //     getTests();
  //   }
  // }, [tableRows.length]);

  useEffect(() => {
    getTests();
  }, [editID, deleteID, testID]);
  
  return (
    <>
      <DashboardLayout>         
        {openAddCandidatePopup && <AddCandidate open={openAddCandidatePopup} close={closeAddCandidatePopup} testID = {testID} setTestID={setTestID} testname = {testNameValue}/> }
        <ShareTest open={openShareTestPopup} close={closeShareTestPopup} testID = {testID} setTestID={setTestID} sharedwithemails = {sharedWIthEmail}/>
        <ViewCandidates open={openViewCandidatesPopup} close={closeViewCandidatesPopup} testid = {testID} setTestID={setTestID} testname = {testNameValue}/>        
        <DeleteTest open={openDeletePopup} close={closeDeletePopup} deleteID={deleteID} setDeleteID={setDeleteID}/>
        <EditTest open={openditPopup} close={closeEditPopup} editID={editID} testname ={testNameValue} noofquestions = {noOfquestionsValue} setEditID={setEditID}/>        
        <TestDetails open={openTestDetailsPopup} close={closeTestDetailsPopup} testdetails = {selectedViewDetails} testname = {testNameValue}/>        
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pt: 3,
            mt: 2,
          }}
        >
          <Container maxWidth={false}>
            <TestToolbar
              search={(value) => search(value.toLowerCase())}         
            />
            <div
              style={{
                height: 700,
                width: "100%",
                marginTop: "24px",
                backgroundColor: "white",
              }}
              className="center_port"
            >
              {tableRows && tableRows.length > 0 &&
                <DataGrid
                  rows={tableRows}
                  columns={columns}                  
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  pageSize={pageSize}
                  rowsPerPageOptions={rowsPerPage}
                  disableColumnSelector
                  hideFooter={tableRows.length < 1 ? true: false}
                  sortingOrder={['asc', 'desc']}
                  className="test_table"
                />
              }
              {loading && tableRows.length === 0 &&
                <CircularProgress />
              }
              {!loading && tableRows.length === 0 &&
                <DataGrid
                rows={tableRows}
                columns={columns}  
                hideFooter={tableRows.length < 1 ? true: false}                
              />
              }
            </div>
            
          </Container>
        </Box>
      </DashboardLayout>
    </>
  );
};

export default Tests;

import React, { useEffect } from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store/hooks";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { useGoogleSignupUserMutation } from "../setup/services/fetch.service";
import { gapi } from "gapi-script";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../css/style.css";
import axios from "axios";
import * as Constants from '../constants';

interface TextOptions {
  firstname?: string;
  lastname?: string;
  token?: string;
  email?: string;
  language?: string;
}

export default function GoogleAuthSignUp() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    gapi?.load("client:auth2", () => {
      gapi?.auth2?.init({
        clientId:
          "893632805073-l3d98lh2o472pppld95otfojni41emun.apps.googleusercontent.com",
      });
    });
  }, []);

  const responsefailureGoogle = (response: any) => {
    console.log(response);
  };

  const [
    googlesignUpUser,
    {
      data: googleSignUpData,
      isSuccess: issignupSuccess,
      isError: issignupError,
    },
  ] = useGoogleSignupUserMutation();

  const responseGoogle = async (response: any) => {
    const decodedUser = jwt_decode<any>(response?.credential);

    const firstName = decodedUser?.given_name;
    const lastName = decodedUser?.family_name;
    const token = response?.credential;
    const email = decodedUser?.email;
    const language = "";

    await googlesignUpUser({
      firstName,
      lastName,
      token,
      email,
      language,
    });
  };

  useEffect(() => {
    if (issignupSuccess) {
      console.log("issignupSuccess ---------",issignupSuccess);
      localStorage.setItem(
        "user",
        JSON.stringify({
          firstName: googleSignUpData?.firstName,
          lastName: googleSignUpData?.lastName,
          email: googleSignUpData?.email,
          language: googleSignUpData?.language,
          JwtToken: googleSignUpData?.JwtToken,
        })
      );

      localStorage.setItem("firstName",googleSignUpData?.firstName);
      localStorage.setItem("lastName",googleSignUpData?.lastName);
      localStorage.setItem("email",googleSignUpData?.email);
      localStorage.setItem("language",googleSignUpData?.language);
      localStorage.setItem("JwtToken",googleSignUpData?.JwtToken);

      console.log(localStorage.getItem("user"));
      toast.success("Thanks for signing up.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate("/tests");
      // addUser();
    }

    if (issignupError) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [issignupSuccess, issignupError]);


  const addUser = () => {
    console.log("Add User")
    var userEmail = localStorage.getItem("email");
    console.log(userEmail)
    var data = {
      "username" : userEmail,
      "password" : "@MPchatgpt!@#$",
      "firstname" : localStorage.getItem("firstName"),
      "lastname" : localStorage.getItem("lastName"),
    }
    axios
      .post(
        Constants.APIURL + "users/" ,data)
      .then((response) => {
        console.log(response);  
        localStorage.setItem("loginId", response.data.id); 
        navigate("/tests");    
      }).catch((err) =>
      console.log(err));    
  }

  const mplogoImg = require("../assets/images/logo_MP.png");
  const chatGPTImg = require("../assets/images/chatGPT_Logo.png");
  const googleImg = require("../assets/images/google.png");
  return (
    <>
      <div className="container-login100">
        <div className="loginInnerBox">
          <span className="d-block imglogo">
            <img src={mplogoImg} className="w-100" />
          </span>
          <div className="loginDiv">
            {/* <img src={chatGPTImg} className="gptlogoImg" />
            <h2> ChatGPT AI </h2> */}
            <GoogleOAuthProvider
              clientId="893632805073-l3d98lh2o472pppld95otfojni41emun.apps.googleusercontent.com"
              // clientId="747515432482-qkthfp9d7hvor9s71iec0i8nkj1pv7gg.apps.googleusercontent.com"
            >
              <GoogleLogin              
                onSuccess={(credentialResponse: any) => {
                  responseGoogle(credentialResponse);
                }}
                onError={() => responsefailureGoogle}
              />
            </GoogleOAuthProvider>
          </div>
        </div>
      </div>
    </>
  );
}

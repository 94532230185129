import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Container, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Constants from "../../constants";
import { Alert } from "../alert";
import CircularProgress from "@mui/material/CircularProgress"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const ShareTest = (props) => {
  const [alert, setAlert] = useState({ open: false, data: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");  
  const [fieldValues, setFieldValues] = useState({ 
    username : ""
  });

  useEffect(() => {
    setError("")
   },[props.open])

  useEffect(() => {
    console.log(props.sharedwithemails);
    var emails = "";
   if(props.sharedwithemails && props.sharedwithemails !== null && props.sharedwithemails !== ""){
    emails = props.sharedwithemails.join(",")
   }
   
    setFieldValues({    
      username : emails
    });
  }, [props.open]);
  
  const handleChange = (evt) => {
    const value = evt.target.value.trim();
    setFieldValues({
      ...fieldValues,
      [evt.target.name]: value,
    });
   
  };

  const handleSubmit = (e) => {
   e.preventDefault();
   setError("");
    var emailValidation = true;
    if(fieldValues.username.trim() === ""){
      setError("Please add the emails with comma separated value to share the test." );
      return;
    }
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var emails = fieldValues.username.toLowerCase().split(",").map(function (value) {
      if(value.match(mailformat)){
        return value;
     
      }else{
        emailValidation = false;        
        return value;
      }      
    });

    if(!emailValidation){
      setError("Please add the valid emails with right email format." );
      return;
    }

    var data = {
    "shareWithEmails" : emails,
    "id" : props.testID
    }

    setLoading(true)
    var JwtToken = localStorage.getItem("JwtToken");

    props.testID &&
      axios
        .post(
          Constants.APIURL + "shareWithEmails",
          data,
          {
            headers: {
              'Authorization': `${JwtToken}`
            }}
        )
        .then((response) => {               
          if (response.data.status !== 200) {
            setAlert({ open: true, data: "The Test has been shared to Email Ids successfully." });
            setLoading(false)
            props.setTestID("");         
            props.close();   
          }       
        }).catch((error) => {
          setLoading(false)
          console.log(error);
          setAlert({ open: true, data: "Something went wrong while sharing the test." });
      });
  };
  return (
    <>
      <Alert
        open={alert.open}
        data={alert.data}
        close={() => setAlert({ ...alert, open: false })}
      />
      <BootstrapDialog
        onClose={props.close}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth="lg"
        className="mobile_modal_content">
        <form onSubmit={handleSubmit}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.close}
            sx={{
              fontSize: "1.5rem",
              fontWeight: "700",
              color: (theme) => theme.palette.primary.main,
              borderBottom: "3px solid #D1D5DB",
            }}
          >
            Share Test
          </BootstrapDialogTitle>
          <DialogContent>          
            <Container sx={{ width: "800px", my: 2 }}>
              <TextField
                required
                fullWidth
                multiline
                type="text"
                label="Share with Email Ids"
                margin="normal"
                variant="outlined"
                name="username"
                value={fieldValues.username}
                onChange={handleChange}
              />
              <Typography style={{ color: "red" }}>{error}</Typography> 
            </Container>
            
          </DialogContent>
          
          <DialogActions sx={{ borderTop: "3px solid #D1D5DB" }}  className="modal_footer">
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={3}>
                <Button
                  color="success"
                  variant="contained"
                  sx={{ px: 20, margin: "0.8rem" }}
                  type="submit"
                  className="buttonTheme"
                >
                  Share
                  { loading &&  <CircularProgress style={{'color': '#fff'}}/> }
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </>
  );
};

export default ShareTest;

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Container, TextField, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Constants from "../../constants";
import { Alert } from "../alert";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const EditTest = (props) => {
  const [alert, setAlert] = useState({ open: false, data: "" });
  const [fieldValues, setFieldValues] = useState({
    testName: props.testname,
    noOfQuestions: props.noofquestions
  });
  useEffect(() => {
    setFieldValues({
      testName: props.testname,
      noOfQuestions: props.noofquestions
    });
  }, [props.testname, props.noofquestions]);
  
  const handleChange = (evt) => {
    const value = evt.target.value;
    setFieldValues({
      ...fieldValues,
      [evt.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var data = {
    "programmingLanguage" : fieldValues.testName,
    "numberOfQuestions" : fieldValues.noOfQuestions,
    "id" : props.editID
    }
    var JwtToken = localStorage.getItem("JwtToken");
    props.editID &&
      axios
        .put(
          Constants.APIURL + "test/" +  props.editID,
          data, {
          headers: {
            'Authorization': `${JwtToken}`
          }}
        )
        .then((response) => {
          props.setEditID("");
          if (response.data.message !== undefined) {
            setAlert({ open: true, data: response.data.message });
          }
          else if (response.data) {
            setAlert({ open: true, data: "The test has been updated successfully."});
          }
        });
    props.close();
  };
  return (
    <>
      <Alert
        open={alert.open}
        data={alert.data}
        close={() => setAlert({ ...alert, open: false })}
      />
      <BootstrapDialog
        onClose={props.close}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth="lg"
        className="mobile_modal_content">
        <form onSubmit={handleSubmit}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.close}
            sx={{
              fontSize: "1.5rem",
              fontWeight: "700",
              color: (theme) => theme.palette.primary.main,
              borderBottom: "3px solid #D1D5DB",
            }}
          >
            Edit Test
          </BootstrapDialogTitle>
          <DialogContent>
          <Container sx={{ width: "800px", my: 2 }}>
            <TextField
              required
              fullWidth
              label="Test Name"
              margin="normal"
              variant="outlined"
              name="testName"
              testname = {props.testname}
              value={fieldValues.testName}
              onChange={handleChange}
            />
          </Container>
        
          <Container sx={{ width: "800px", my: 2 }}>
            <TextField
              required
              fullWidth
              type="number"
              label="No Of Questions"
              margin="normal"
              variant="outlined"
              name="noOfQuestions"
              noofquestions = {props.noofquestions}
              value={fieldValues.noOfQuestions}
              onChange={handleChange}
            />
          </Container>
        </DialogContent>
          <DialogActions sx={{ borderTop: "3px solid #D1D5DB" }} className="modal_footer">
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={3}>
                <Button
                  color="success"
                  variant="contained"
                  sx={{ px: 20, margin: "0.8rem" }}
                  type="submit"
                  className="buttonTheme"
                >
                  UPDATE
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </>
  );
};

export default EditTest;

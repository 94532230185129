import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Container,
  Grid,
  TextField,
  Link,
  Box,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import * as Constants from "./../../constants";
import { Alert } from "../alert";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const ForgotPassword = (props) => {
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ open: false, data: "" });
  const alertClose = () => setAlert({ ...alert, open: false });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      const data = {
        email: values.email,
      }
      axios.post(Constants.APIURL +'api/v1/reset/password',
        data,
      ).then(response => {
        if(response && response.data.status === 200) {
          setAlert({ open: true, data: response.data.message + '. Please check your email.'});
          setOpen(false);
        } else {
          setAlert({ open: true, data: "Something went wrong. Please try again." });
        }
      }).catch(err => {
        if(err.response) {
          setAlert({ open: true, data: err.response.data.message });
          setOpen(false);
        }
      });
    },
  });

  return (
    <>
    <Alert open={alert.open} data={alert.data} close={alertClose} />
      <Link
        href="#"
        variant="body2"
        onClick={handleClickOpen}
        sx={{ color: "primary.main", fontWeight: 700, textDecoration: "none" }}
      >
        Forgot Password?
      </Link>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        width="600"
        open={open}
        maxWidth="lg"
      >
      <form onSubmit={formik.handleSubmit}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          alignItems="center"
          onClose={handleClose}
          sx={{
            fontSize: "1.5rem",
            fontWeight: "700",
            color: (theme) => theme.palette.primary.main,
            borderBottom: "3px solid #D1D5DB",
          }}
        >
          Reset Password
        </BootstrapDialogTitle>
        <DialogContent>
          <Container sx={{ width: "600px", my: 2 }}>
            <Typography
              sx={{
                color: "black",
                fontWeight: 500,
                fontSize: "1.2rem",
                mt: 2,
              }}
            >
              Please enter the email address you'd like your password reset
              information sent to
            </Typography>
            <Box
              sx={{
                my: 2,
                px: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                value={formik.values.email}
                onChange={formik.handleChange}
                margin="normal"
                variant="outlined"
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>
          </Container>
        </DialogContent>
        <DialogActions sx={{ borderTop: "3px solid #D1D5DB" }}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              color="success"
              type="submit"
              variant="contained"
              sx={{
                px: 27,
                margin: "0.4rem"               
              }}
              className="buttonTheme"
            >
              SEND
            </Button>
          </Grid>
        </DialogActions>
        </form>
      </BootstrapDialog>
    </>
  );
};

import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Tests from "./pages/tests";
import Candidates from "./pages/candidates";
import "./App.css";
import Login from "./pages/Login";
import UserProfile from "./pages/UserProfile";
import GoogleAuthSignUp from "./components/googleSignup.tsx";
import GoogleAuthWrapper from "./authentication/SignupWrapper.tsx";
import QuestionAnswers from "./pages/questionAnswers";
import CandidateReport from "./pages/candidateReport";
import IdleTimeOutHandler from './components/IdleTimeOutHandler'

function App() {
  const location = useLocation();
  const[isActive,setIsActive]=useState(true)
  const[isLogout,setLogout]=useState(false)
  return (
    <div className="App">  
      <IdleTimeOutHandler onActive={()=>{setIsActive(true)}} onIdle={()=>{setIsActive(false)}} onLogout={()=>{setLogout(true)}} />   
      <Routes>
        <Route path="/" element={  
            (localStorage.getItem("user")) ?
            <Navigate to="/tests" replace state={{ path: location.pathname }} />
            : <GoogleAuthSignUp />
                 
          }/>
        <Route path="/googleAuthWrapper" element={<GoogleAuthWrapper />} />
        <Route
          path="/tests"
          element={  
            (localStorage.getItem("user")) ?
            <Tests /> 
            : <Navigate to="/" replace state={{ path: location.pathname }} />
                 
          }
        />
         <Route
          path="/candidates"
          element={  
            (localStorage.getItem("user")) ?
            <Candidates /> 
            : <Navigate to="/" replace state={{ path: location.pathname }} />
                 
          }
        />
        <Route
          path="/questionAnswers/:id"
          element={  
            (localStorage.getItem("user")) ?
            <QuestionAnswers /> 
            : <Navigate to="/" replace state={{ path: location.pathname }} />
                 
          }
        />
         <Route
          path="/CandidateReport/:id"
          element={  
            (localStorage.getItem("user")) ?
            <CandidateReport /> 
            : <Navigate to="/" replace state={{ path: location.pathname }} />
                 
          }
        />
        {/* <Route
          path="/"
          element={          
              <Login />      
          }
        />         */}
        <Route
          path="/profile"
          element={   
            (localStorage.getItem("user")) ?  
              <UserProfile />      
              : <Navigate to="/" replace state={{ path: location.pathname }} /> 
          }
        />
      </Routes>
    </div>
  );
}

export default App;
